import React from 'react'
import { connect } from "react-redux"
import Axios from '../../axios/index'

import { MAIN_SELECTED, VIDEO_SELECTED, STUDY_SELECTED, PERSON_SELECTED } from '../../config/footer'
import { SearchBar, Flex, ActionSheet, Toast, Modal } from 'antd-mobile';
import { HashRouter, Route, Switch, Redirect, NavLink } from 'react-router-dom'
import './index.less'
import * as fn from '../../utils/index'
import { hiden_wechat_menu } from "../../utils/index"

export default class Default extends React.Component {
        
        render() {
                let that = this;
               

                return (
                        <div className="withdraw-container-view" >
                        系统繁忙，请稍后再试！
                        </div>
                )
        }
}
