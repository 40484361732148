/*
 * action 类型
 */

export const type = {
    SET_TOKEN : 'SET_TOKEN',
    SET_SHOP_INFO : 'SET_SHOP_INFO',
    SET_SHARE_ID : 'SET_SHARE_ID',
    SET_COURSE_ID : 'SET_COURSE_ID',
    SET_COURSE_CATE_ID : 'SET_COURSE_CATE_ID',
    SET_COURSE_CONTENT_ID : 'SET_COURSE_CONTENT_ID',
    SET_COURSE_SEARCH_TITLE : 'SET_COURSE_SEARCH_TITLE',
    SET_LEARN_COUPON_ID:"SET_LEARN_COUPON_ID",
    SET_DISPATCH_USER_ID :"SET_DISPATCH_USER_ID"
  
}
// 切换页面
export function set_token(token) {
    return {
        type:type.SET_TOKEN,
        token:token
    }
}
// 设置商家信息
export function set_shop_info(shopinfo) {
    return {
        type:type.SET_SHOP_INFO,
        shopinfo:shopinfo
    }
}
// 设置商家信息
export function set_share_id(s) {
    return {
        type:type.SET_SHARE_ID,
        s:s
    }
}
// 切换页面
export function set_course_id(course_id) {
    return {
        type:type.SET_COURSE_ID,
        course_id:course_id
    }
}
// 设置课程分类
export function set_course_cate_id(course_cate_id) {
    return {
        type:type.SET_COURSE_CATE_ID,
        course_cate_id:course_cate_id
    }
}
// 设置课程搜索标题
export function set_course_search_title(course_search_title) {
    return {
        type:type.SET_COURSE_SEARCH_TITLE,
        course_search_title:course_search_title
    }
}

// 设置当前图文id
export function set_course_content_id(course_content_id) {
    return {
        type:type.SET_COURSE_CONTENT_ID,
        course_content_id:course_content_id
    }
}

// 设置跳转页面地址
export function set_dispatch_user_id(dispatch_user_id){
    return {
        type:type.SET_DISPATCH_USER_ID,
        dispatch_user_id:dispatch_user_id
    }
}

export function set_learn_coupon_id(learn_coupon_id){
    return {
        type:type.SET_LEARN_COUPON_ID,
        learn_coupon_id:learn_coupon_id
    }
}
