import React from 'react'
import { connect } from "react-redux"
import Axios from '../../axios/index'

import { MAIN_SELECTED, VIDEO_SELECTED, STUDY_SELECTED, PERSON_SELECTED } from '../../config/footer'
import { SearchBar, Flex, ActionSheet, Toast, Modal } from 'antd-mobile';
import { HashRouter, Route, Switch, Redirect, NavLink } from 'react-router-dom'
import './index.less'
import * as fn from '../../utils/index'
import { hiden_wechat_menu } from "../../utils/index"
const prompt = Modal.prompt;
class Withdraw extends React.Component {
        state = {
                goto_applay_withdraw: false,
                type: 'CARD',
                server_qr_code: '',
                consult_modal_gate: false,
                withdraw_info: []
        }
        apply_withdraw() {
                let that = this;
                let type = that.state.type;
                if (type == 'CARD') {
                        // 请先绑定银行卡
                        if (!this.state.withdraw_info.bankname) {
                                Toast.info('请先绑定银行卡', 1);
                                return false;
                        }
                }

                // 无可提现金额不可提现
                if (this.state.withdraw_info.will_amount < 0.01) {
                        Toast.info('暂无可提现金额', 1);
                        return false;
                }
                // 当有提现记录审核时，不可提交
                let withdraw_list = that.state.withdraw_info.withdraw_list;
                if (withdraw_list.length > 0 && withdraw_list[0]['status'] == "WAIT") {
                        Toast.info('提现审核中，不可重复提交', 1);
                        return false;
                }
                prompt(
                        '申请提现',
                        '可提现金额为0.01-' + that.state.withdraw_info.will_amount,
                        [
                                {
                                        text: '取消',
                                        onPress: value => new Promise((resolve) => {
                                                resolve();
                                        }),
                                },
                                {
                                        text: '提交',
                                        onPress: (v) => new Promise((resolve, reject) => {
                                                let withdraw_price = parseFloat(v);

                                                console.log(withdraw_price);
                                                if (withdraw_price < 0.01 || withdraw_price > that.state.withdraw_info.will_amount || !withdraw_price) {
                                                        Toast.info('请输入0.01-' + that.state.withdraw_info.will_amount + "之间的金额", 1);

                                                        reject();
                                                } else {
                                                        resolve();
                                                        // 申请提现
                                                        Axios.ajax({
                                                                url: '/apply_withdraw.php',
                                                                data: {
                                                                        token: this.props.token,
                                                                        withdraw_price: withdraw_price,
                                                                        type: type,
                                                                        isShowLoading: false
                                                                }
                                                        }).
                                                                then((res) => {
                                                                        that.init_data();
                                                                })
                                                }
                                        }),
                                },
                        ]
                        ,
                        '',
                        '',
                        ['请输入提现金额']
                )
        }
        // 监听提现类型
        change_withdraw_type() {
                let that = this;
                ActionSheet.showActionSheetWithOptions({
                        options: [<span className="withdraw-fon1">提现到银行卡</span>, <span className="withdraw-fon1">微信好友转账</span>],
                        maskClosable: true
                },
                        (buttonIndex) => {
                                console.log(buttonIndex)
                                if (buttonIndex == -1) return false;
                                else if (buttonIndex == 0) {
                                        that.setState({
                                                type: "CARD"
                                        })
                                }
                                else if (buttonIndex == 1) {
                                        // fn.toast("请确保添加客服微信");

                                        that.setState({
                                                type: "WX+",
                                                consult_modal_gate: true
                                        })
                                }
                        });
        }

        componentDidMount() {
                this.init_data();
        }
        // 显示客服弹窗
        show_consult_model() {
                let that = this;
                that.setState({
                        consult_modal_gate: true
                })
        }
        // 隐藏客服弹窗
        hide_consult_model() {
                let that = this;
                that.setState({
                        consult_modal_gate: false
                })

        }
        // 初始化数据
        init_data() {
                let that = this;
                Axios.ajax({
                        url: '/withdraw_list.php',
                        data: {
                                token: this.props.token
                        }
                }).
                        then((res) => {
                                that.setState({
                                        withdraw_info: res.data,
                                        server_qr_code: res.data.server_qr_code
                                })
                        })
        }
        render() {
                let that = this;
                if (this.props.shopinfo) { hiden_wechat_menu(this.props.shopinfo); }
                if (this.state.goto_applay_withdraw) {
                        return (<Redirect to="/apply_withdraw" />);
                }
                let shengque_view = null;
                let footer_hint = null;
                if (this.state.withdraw_info.withdraw_list && this.state.withdraw_info.withdraw_list.length == 0) {
                        shengque_view = (<div className="none-order">
                                <img src="/assets/quesheng_img.png" className="pic-zhangdan-style" />
                                <text className="none-order-font">暂无提现记录</text>
                        </div>)
                }
                let withdraw_list = null;
                if (this.state.withdraw_info.withdraw_list) {
                        withdraw_list = this.state.withdraw_info.withdraw_list.map((item, index) => {
                                if (index != 0) return null;
                                return (
                                        <div className="withdraw-list-view" key={item.id}>
                                                <div className="withdraw-list-top">
                                                        <text className="withdraw-list-label">订单编号 : {item.trade_no}</text>
                                                        <text className="withdraw-list-label">{item.create_time}</text>
                                                </div>
                                                <div className="withdraw-list-down">
                                                        <text className="withdraw-list-price">提现金额 : {item.total_amount}元</text>
                                                        {item.status == 'SUCCESS' ? <text className="withdraw-list-status font-greent">成功</text> : ''}
                                                        {item.status == 'FAIL' ? <text className="withdraw-list-status font-red">失败</text> : ''}
                                                        {item.status == 'WAIT' ? <text className="withdraw-list-status ">审核中</text> : ''}
                                                </div>
                                        </div>
                                )

                        })
                        if (this.state.withdraw_info.withdraw_list.length > 3) {
                                footer_hint = (<div className="footer-hint-view">
                                        —— 我也是有底线的哦 ——
                                </div>)

                        }

                }

                return (
                        <div >
                                <div className="withdraw-header-view-final">
                                        <div className="header-wrap-row-view">
                                                <text className="withdraw-balance-label-final">可提现(元)</text>
                                                <text className="withdraw-balance-price-final">{this.state.withdraw_info.will_amount}</text>
                                        </div>
                                        <div className="header-wrap-row-view" style={{border:"none"}}>
                                                <text className="withdraw-balance-label-final">已使用(元)</text>
                                                <text className="withdraw-balance-price-final">{this.state.withdraw_info.use_balance}</text>
                                        </div>
                                        <div className="header-wrap-row-view" style={{border:"none"}}>
                                                <text className="withdraw-balance-label-final">审核中(元)</text>
                                                <text className="withdraw-balance-price-final">{this.state.withdraw_info.verify_amount}</text>
                                        </div>
                                        
                                        <div className="header-hint-view">
                                                ps：微信支付7天审核周期，审核通过即可提现
                                        </div>
                                </div>
                                

                                <div className="withdraw-header-row-view" >
                                        <div className="withdraw-row-label-view">
                                                <text className="withdraw-font1">提现类型</text>
                                        </div>
                                        <div className="withdraw-row-value-view" onClick={this.change_withdraw_type.bind(this)}>
                                                <text className="withdraw-font2"  >{that.state.type == 'WX+' ? '微信好友转账' : '提现到银行卡'}</text>
                                                <img src="/assets/arrow_right.png" className="arrow-right-style" />
                                        </div>
                                </div>
                                {this.state.type == 'WX+' ? '' : <div className="withdraw-header-row-view">
                                        <div className="withdraw-row-label-view">
                                                <text className="withdraw-font1">提现银行卡</text>
                                        </div>
                                        <NavLink className="withdraw-row-value-view" to="/apply_withdraw">
                                                <text className="withdraw-font2"  >{this.state.withdraw_info.bankname ? this.state.withdraw_info.bankname : '请添加提现银行卡'}</text>
                                                <img src="/assets/arrow_right.png" className="arrow-right-style" />
                                        </NavLink>
                                </div>}
                   
                                {/* <div className="withdraw-nav-view">
                                        <div className="withdraw-nav-module">
                                                <text className="withdraw-nav-label">本月收益</text>
                                                <text className="withdraw-nav-price">￥{this.state.withdraw_info.now_month_withdraw_amount}</text>
                                        </div>
                                        <div className="withdraw-nav-module">
                                                <text className="withdraw-nav-label">累计推广(笔)</text>
                                                <text className="withdraw-nav-price">{this.state.withdraw_info.order_count}</text>
                                        </div>
                                        <div className="withdraw-nav-module" style={{ borderRight: "none" }}>
                                                <text className="withdraw-nav-label">累计收益(元)</text>
                                                <text className="withdraw-nav-price">{this.state.withdraw_info.order_amount}</text>
                                        </div>
                                </div> */}

                                <div className="withdraw-view">
                                        <div className="withdraw-header">提现记录</div>
                                        <div className="withdraw-wrap-scroll-view">
                                                {withdraw_list}
                                                {shengque_view}
                                                {footer_hint}

                                        </div>
                                </div>
                                <div className="blanket-view"></div>
                                <div className="withdraw-button" onClick={this.apply_withdraw.bind(this)}>申请提现</div>
                                <Modal
                                        visible={that.state.consult_modal_gate}
                                        transparent
                                        onClose={that.hide_consult_model.bind(this)}
                                        maskClosable={true}
                                        afterClose={() => { that.hide_consult_model(); }}
                                >
                                        <div className="consult-modal-wrap">
                                                <div className="consult-modal-wrap-title">
                                                        <text>长按下方二维码添加客服微信</text>
                                                        <text>(如已添加请忽略)</text>
                                                </div>

                                                {that.state.server_qr_code ? <img src={that.state.server_qr_code} className="consult-qrcode-icon" /> : <div className="consult-qrcode-none">暂无客服微信</div>}

                                        </div>
                                </Modal>
                        </div >
                );
        }
}
const mapStateToProps = state => {

        return {
                token: state.token, shopinfo: state.shopinfo
        }
};
export default connect(mapStateToProps)(Withdraw)