/**
 * reducer
 */


import { type } from '../action';


//console.log(userinfo)
const initialState = {

    course_cate_id: 0,
    course_search_title: '',
    course_id: '',
    learn_coupon_id:'',
    s: '',
    course_content_id: '',
    dispatch_user_id: 0,
    shopinfo: null,
    token: ""
}

const ebikeData = (state = initialState, action) => {
    switch (action.type) {
        case type.SET_COURSE_ID:

            return {
                ...state,
                course_id: action.course_id
            };
        case type.SET_COURSE_CATE_ID:

            return {
                ...state,
                course_cate_id: action.course_cate_id
            };
        case type.SET_COURSE_SEARCH_TITLE:

            return {
                ...state,
                course_search_title: action.course_search_title
            };
        case type.SET_TOKEN:

            return {
                ...state,
                token: action.token
            };
        case type.SET_SHOP_INFO:

            return {
                ...state,
                shopinfo: action.shopinfo
            };
        case type.SET_SHARE_ID:

            return {
                ...state,
                s: action.s
            };
        case type.SET_COURSE_CONTENT_ID:

            return {
                ...state,
                course_content_id: action.course_content_id
            };
        case type.SET_DISPATCH_USER_ID:

            return {
                ...state,
                dispatch_user_id: action.dispatch_user_id
            };
        case type.SET_LEARN_COUPON_ID:

            return {
                ...state,
                learn_coupon_id: action.learn_coupon_id
            };

        default:
            return { ...state };
    }
};

export default ebikeData;