import React from 'react'
import { connect } from "react-redux"
import Axios from '../../axios/index'
import { set_course_id } from '../../redux/action'
import { NavLink } from 'react-router-dom'
import { VIDEO_SELECTED, STUDY_SELECTED, PERSON_SELECTED } from '../../config/footer'
import { SearchBar, Picker, ActionSheet, WingBlank } from 'antd-mobile';
import './index.less'
import { hiden_wechat_menu } from "../../utils/index"
class Course extends React.Component {

        state = {
                data: [{ value: 1 }, { value: 2 }, { value: 3 }],
                cols: 1,
                course_search_title: '',
                course_order_index: 0,
                course_cate_index: 0,
                course_order: ["按时间排序", "按热度排序"],
                course_cate_title: [],
                course_cate: [],
                course_cate_data: [],
                cate_arr: [],
                new_course: []
        }

        change_order = (val) => {
                let that = this;

                ActionSheet.showActionSheetWithOptions({
                        options: that.state.course_order,
                        maskClosable: true
                },
                        (buttonIndex) => {

                                if (buttonIndex == that.state.course_order_index || buttonIndex == -1) return false;
                                that.setState({
                                        course_order_index: buttonIndex
                                })
                                this.initData(null, buttonIndex);
                        });

        }
        change_cate = (val) => {
                let that = this;

                ActionSheet.showActionSheetWithOptions({
                        options: that.state.course_cate_title,
                        maskClosable: true
                },
                        (buttonIndex) => {
                                if (buttonIndex == that.state.course_cate_index || buttonIndex == -1) return false;
                                that.setState({
                                        course_cate_index: buttonIndex
                                })
                                this.initData(buttonIndex);

                        });

        }

        // 跳转到全部课程
        goto_course_detail(course_id) {
                const { dispatch } = this.props;
                dispatch(set_course_id(course_id));

        }
        componentDidMount() {
                this.setState({
                        course_search_title: this.props.course_search_title
                })
                this.init_course_cate();
                this.initData(null, null, this.props.course_search_title);
        }

        init_course_cate() {
                let data = {};
                let course_cate_id = this.props.course_cate_id;
                let that = this;
                data.token = this.props.token;
                Axios.ajax({
                        url: '/get_course_cate.php',
                        data: data
                }).
                        then((res) => {
                                let course_cate = [];
                                let course_cate_title = [];
                                let tmp = { id: "0", title: "全部分类" };
                                let tmp_title = "全部分类";

                                course_cate.push(tmp);
                                course_cate_title.push(tmp_title);
                                let tmp_var = 0;
                                if (res.data) {
                                        for (let i = 0; i < res.data.length; i++) {
                                                course_cate.push(res.data[i]);
                                                course_cate_title.push(res.data[i].title);
                                                if (that.props.course_cate_id == res.data[i].id) {
                                                        tmp_var = i + 1;
                                                        that.setState({
                                                                course_cate_index: tmp_var
                                                        })
                                                }

                                        }
                                }


                                this.setState({
                                        cate_arr: res.data,
                                        course_cate_title: course_cate_title,
                                        course_cate: course_cate
                                })

                        })

        }
        // 初始化数据
        initData(course_cate_index = null, course_order_index = null, title = this.state.course_search_title) {
                let that = this;
                let data = {};
                data.title = title;
                data.token = this.props.token;

                if (course_cate_index == null) {
                        data.course_cate_id = this.props.course_cate_id;


                } else {
                        data.course_cate_id = this.state.course_cate[course_cate_index].id;

                }
                if (course_order_index == null) {
                        data.course_order_index = this.state.course_order_index + 1;

                } else {
                        data.course_order_index = course_order_index + 1;


                }
                data.isShowLoading = false;
                Axios.ajax({
                        url: '/course_list.php',
                        data: data
                }).
                        then((res) => {

                                that.setState({
                                        new_course: res.data
                                })


                        })
        }
        change_search_key = (value) => {
                this.setState({
                        course_search_title: value
                })
        }
        // 搜索
        search() {
                this.setState({
                        course_order_index: 0,
                        course_cate_index: 0
                })
                this.initData();
        }
        render() {

                if (this.props.shopinfo) { hiden_wechat_menu(this.props.shopinfo); }
                let shengque_view = null;
                let new_course = null;
                if (this.state.new_course == undefined || this.state.new_course.length == 0) {
                        shengque_view = (<div className="none-order" >
                                <img src="/assets/quesheng_img.png" className="pic-zhangdan-style" />
                                <text className="none-order-font">正在准备内容</text>
                        </div>)
                }
                if (this.state.new_course) {
                        new_course = this.state.new_course.map((item) => {
                                return (
                                        <NavLink className="course-list-view" key={item.id} to="/course_detail" onClick={this.goto_course_detail.bind(this, item.id)}>
                                                <div className="course-wrap-left">
                                                        <img src={item.course_cover} className="course-cover-icon" />
                                                        {/* {
                                                                item.count > 0 ? <div className="course-sale"> 已学习 {item.count} 次</div> : ''
                                                        } */}
                                                </div>
                                                <div className="course-wrap-right">
                                                        <div className="course-title">{item.title}</div>
                                                        {
                                                                item.tag != null ? <div className="course-description">{item.tag}</div> : ''
                                                        }

                                                        <div className="course-price">{item.is_free == 0 ? '￥' + item.price : '免费'}</div>
                                                </div >
                                        </NavLink >
                                )

                        })

                }

                let defaultValue = this.state.course_search_title;
                let course_cate_title = null;
                if (this.state.course_cate[this.state.course_cate_index]) {
                        course_cate_title = this.state.course_cate[this.state.course_cate_index].title;
                }

                return (
                        <div >
                                <SearchBar placeholder="请输入课程名称" maxLength={20} value={defaultValue} onSubmit={this.search.bind(this)} onChange={this.change_search_key} />
                                <div className="course-filter-view">
                                        <div className="course-filter-module" onClick={this.change_order}>
                                                <text className="course-filter-name">{this.state.course_order[this.state.course_order_index]}</text>
                                                <img src="/assets/down_arrow.png" className="course-filter-icon" />
                                        </div>
                                        <div className="course-filter-module" onClick={this.change_cate}>
                                                <text className="course-filter-name">{course_cate_title}</text>
                                                <img src="/assets/down_arrow.png" className="course-filter-icon" />
                                        </div>
                                </div>
                                {new_course}
                                {shengque_view}
                                <div className="blanket-view"></div>
                                {VIDEO_SELECTED}
                        </div >


                );
        }
}
const mapStateToProps = state => {

        return {
                token: state.token, shopinfo: state.shopinfo,
                course_cate_id: state.course_cate_id,
                course_search_title: state.course_search_title
        }
};
export default connect(mapStateToProps)(Course)