import React from 'react'
import { connect } from "react-redux"
import Axios from '../../axios/index'
import { set_course_id } from '../../redux/action'
import { MAIN_SELECTED, VIDEO_SELECTED, STUDY_SELECTED, PERSON_SELECTED } from '../../config/footer'
import { SearchBar, Flex, WhiteSpace, WingBlank } from 'antd-mobile';
import './index.less'
import { NavLink } from 'react-router-dom'
import { hiden_wechat_menu } from "../../utils/index"
class Distribute_order extends React.Component {
        state = {
                active_tab:0,
                level_one_data: [],
                level_two_data: []
        }
        componentDidMount() {
                let that = this;
                Axios.ajax({
                        url: '/get_children_order.php',
                        data: {
                                token: this.props.token
                        }
                }).
                        then((res) => {
                                that.setState({
                                        level_one_data: res.data.level_one_data,
                                        level_two_data: res.data.level_two_data
                                })
                        })
        }
        // 跳转到课程详情
        goto_course_detail(course_id) {
                const { dispatch } = this.props;
                dispatch(set_course_id(course_id));

        }
        // 切换tab展示
        change_tab(v) {
                this.setState({
                        active_tab: v
                })
        }
        render() {
                let that = this;
                let level_one_data = that.state.level_one_data;
                let level_two_data = that.state.level_two_data;
                let level_one_view = null;
                let level_two_view = null;
                if (this.props.shopinfo) { hiden_wechat_menu(this.props.shopinfo); }
                if (level_one_data) {
                        level_one_view = level_one_data.map((item) => {
                                return (
                                        <NavLink className="distribute-course-list" key={item.course_id} to="/course_detail" onClick={this.goto_course_detail.bind(this, item.course_id)}>
                                                <div className="distribute-course-left">
                                                        <img src={item.course_cover} className="distribute-course-cover-style" />
                                                </div>
                                                <div className="distribute-course-right">
                                                <text className="distribute-course-font1">{item.title}</text>
                                                        <text className="distribute-course-font2">推广时间：{item.create_time}</text>
                                                        <text className="distribute-course-font2">订阅用户：{item.nickname}</text>
                                                        <text className="distribute-course-font2">结算状态：{item.type==1?<span className="distribute-course-font4">可提现</span>:'审核中'}</text>
                                                        <text className="distribute-course-font2">专栏费用：<span className="distribute-course-font3">￥{item.total_amount}</span>  </text>
                                                        <text className="distribute-course-font2">佣金奖励：<span className="distribute-course-font3">￥{item.promot_amount}</span>  </text>
                                                        
                                                </div>
                                        </NavLink>
                                )
                        })
                }
                if (level_two_data) {
                        level_two_view = level_two_data.map((item) => {
                                return (
                                        <NavLink className="distribute-course-list" key={item.course_id} to="/course_detail" onClick={this.goto_course_detail.bind(this, item.course_id)}>
                                                <div className="distribute-course-left">
                                                        <img src={item.course_cover} className="distribute-course-cover-style" />
                                                </div>
                                                <div className="distribute-course-right">
                                                <text className="distribute-course-font1">{item.title}</text>
                                                        <text className="distribute-course-font2">推广时间：{item.create_time}</text>
                                                        <text className="distribute-course-font2">订阅用户：{item.nickname}</text>
                                                        <text className="distribute-course-font2">结算状态：{item.type==1?<span className="distribute-course-font4">可提现</span>:'审核中'}</text>
                                                        <text className="distribute-course-font2">专栏费用：<span className="distribute-course-font3">￥{item.total_amount}</span>  </text>
                                                        <text className="distribute-course-font2">佣金奖励：<span className="distribute-course-font3">￥{item.promot_amount}</span>  </text>
                                                        
                                                </div>
                                        </NavLink>
                                )
                        })
                }
                return (
                        <div>
                                <div className="distribute-nav-view">
                                        <div className={this.state.active_tab == 0 ? 'nav-row-view nav-active' : 'nav-row-view'} onClick={this.change_tab.bind(this, 0)}>直接推广订单</div>
                                        <div className={this.state.active_tab == 1 ? 'nav-row-view nav-active' : 'nav-row-view'} onClick={this.change_tab.bind(this, 1)}>间接推广订单</div>
                                </div>
                                {this.state.active_tab == 0 ? level_one_view : level_two_view}
                        </div>
                );
        }
}
const mapStateToProps = state => {
        return {
                token: state.token, shopinfo: state.shopinfo
        }
};
export default connect(mapStateToProps)(Distribute_order)