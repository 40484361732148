import React from 'react'
import { connect } from "react-redux"
import Axios from '../../axios/index'
import './index.less'
import { List, InputItem } from 'antd-mobile';
import { Button } from 'antd-mobile';
import  * as fn from '../../utils/index'
import {  Redirect } from 'react-router-dom'
import { hiden_wechat_menu } from "../../utils/index"
class Bind_mobile extends React.Component {
        state = {
                total_time:65,
                remain_time:0,
                mobile:'',
                pswd:'',
                code:'',
                timer:null,
                has_login:false
        }
        // 监听密码
        change_pswd=(v)=>{
                console.log(v)
                this.setState({
                        pswd:v
                })
        }
        // 监听手机号
        change_mobile=(v)=>{
                console.log(v)
                this.setState({
                        mobile:v
                })
        }
        // 监听验证码
        change_code=(v)=>{
                console.log(v)
                this.setState({
                        code:v
                })
        }

        // 跳转到全部课程
        goto_main() {
        }
        // 发送验证码
        send_smd(){
                let that = this;
                let timer=that.state.timer;
                let mobile=that.state.mobile;
                mobile=fn.remove_blanket_str(mobile);
                // if(!fn.isPoneAvailable(mobile)){
                //         fn.toast("请输入正确手机号", 1);
                //         return false;
                // }
                // 冼清除定时器
                if(timer!=null){
                        clearInterval(that.state.timer)
                        that.setState({
                                timer:null
                        })
                }
                // 初始化定时器
                
                timer=setInterval(function(){
                        if(that.state.remain_time<=0){
                                clearInterval(that.state.timer)
                                that.setState({
                                        timer:null
                                }) 
                        }
                        //每秒减少
                        let remain_time=that.state.remain_time;
                        remain_time--;
                        that.setState({
                                remain_time:remain_time
                        })
                        console.log(that.state)
                },1000)
                that.setState({
                        timer:timer,
                        remain_time:that.state.total_time
                })
                
                // 发送验证码
                Axios.ajax({
                        url: '/send_sms.php',
                        data: {
                                mobile:mobile,
                                isShowLoading:false,
                                token: this.props.token
                        }
                }).then((res) => {
                        fn.toast("已发送验证码", 1);
                })
        }
        // 提交
        submit=()=>{
                let that =this;
                let code=that.state.code;
                let mobile=that.state.mobile;
                let pswd=that.state.pswd;
                mobile=fn.remove_blanket_str(mobile);
                // if(!fn.isPoneAvailable(mobile)){
                //         fn.toast("请输入正确手机号", 1);
                //         return false;
                // }
                if(!code||code.length<6){
                        fn.toast("验证码非法", 1);
                        return false;
                }
                if(!pswd||(pswd.length<4||pswd.length>16)){
                        fn.toast("密码需为4-16位字符", 1);
                        return false;
                }
                // 校验手机号与code
                Axios.ajax({
                        url: '/bind_mobile.php',
                        data: {
                                code:code,
                                mobile:mobile,
                                isShowLoading:false,
                                pswd:pswd,
                                token: this.props.token
                        }
                }).then((res) => {
                        that.setState({
                                has_login:true
                        })
                        fn.toast("绑定手机号成功", 1);
                })

        }
        componentDidMount() {
                let that = this;
                // Axios.ajax({
                //         url: '/main.php',
                //         data: {
                //                 token: this.props.token
                //         }
                // }).then((res) => {
                //         that.setState({
                //                 new_course: res.data.new_video
                //         })
                // })
        }
        render() {
                if (this.state.has_login) {
                        return (
                                <Redirect to="/main" />
                        );
                }
                if (this.props.shopinfo) { hiden_wechat_menu(this.props.shopinfo); }
                let remain_time=this.state.remain_time;
                return (
                        <div className="container-content" > 
                                <div className="login-div">
                                        <img src="/assets/gupiao_logo.png"  class="gupiao-logo-style"/>
        
                                        <div className="login-div-row">
                                                <InputItem placeholder="请输入手机号" type="phone"  className="inputitem-style"  onChange={this.change_mobile} />
                                        </div>
                                        <div className="login-div-row">
                                                <InputItem placeholder="请输入密码(4-16位任意字符)" type="password"  className="inputitem-style"  onChange={this.change_pswd} />
                                        </div>
                                        <div className="login-div-row">
                                                <InputItem placeholder="请输入验证码"  maxLength={6} className="inputitem-style" onChange={this.change_code} />
                                                {
                                                        remain_time<=0?<Button type="primary" className="send-code-btn" onClick={this.send_smd.bind(this)}>发送验证码</Button>:<Button type="primary" className="send-code-btn" >还剩 {remain_time} 秒</Button>
                                                }
                                                
                                        </div>
                                        <div className="login-div-row">
                                                <Button type="primary" className="submit-btn" onClick={this.submit} >注册</Button>
                                        </div>
                                </div>
                                <div class="footer-hint">本产品由征牛大数据提供技术支持</div>
                        </div >


                );
        }
}
const mapStateToProps = state => {

        return {
                token: state.token,
                shopinfo: state.shopinfo
        }
};
export default connect(mapStateToProps)(Bind_mobile)