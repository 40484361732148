import React from 'react'
import { connect } from "react-redux"
import { set_course_id,set_learn_coupon_id } from '../../redux/action'
import { NavLink, Redirect } from 'react-router-dom'
import { MAIN_SELECTED, VIDEO_SELECTED, STUDY_SELECTED, PERSON_SELECTED } from '../../config/footer'
import { SearchBar, Flex, WhiteSpace, WingBlank, ActionSheet } from 'antd-mobile';
import './index.less'
import { hiden_wechat_menu } from "../../utils/index"
import * as fn from '../../utils/index'
import Axios from '../../axios/index'
import { message } from 'antd'

class Pay_order extends React.Component {
        state = {
                data: {},
                curent_type: -1,
                coupon_title: '',
                coupon_id: '',
                coupon_amount: 0,
                has_pay: false,
                use_balance: false,
                learn_coupon_id:"",
                order_total_amount: 0//订单总金额


        }
        componentDidMount() {
             
                let that = this;
                let learn_coupon_id=this.props.learn_coupon_id
                if(learn_coupon_id!=""){
                        that.setState({
                                learn_coupon_id:learn_coupon_id
                        })
                        const { dispatch } = this.props;
                        dispatch(set_learn_coupon_id(""))

                }
                
                if (this.props.course_id) {
                        that.initData(learn_coupon_id);
                }
        }
        initData(learn_coupon_id=this.state.learn_coupon_id) {
                let that = this;

                Axios.ajax({
                        url: '/course_order_info.php',
                        data: {
                                token: this.props.token,
                                course_id: that.props.course_id,
                                coupon_id:learn_coupon_id,
                                s:that.props.s
                        }
                }).
                        then((res) => {
                                that.setState({
                                        data: res.data
                                })

                        })
                let now_date = fn.get_current_date();
                that.setState({
                        now_date: now_date
                })
        }
        // 选择订阅类型
        change_type() {
                let that = this;
                let price_rule = that.state.data.price_rule;
                let options = [];
                for (const v in price_rule) {
                        let time = '';
                        if (price_rule[v].time == 3) {
                                time = "一个季 ( ";
                        } else if (price_rule[v].time == 6) {
                                time = "半年 ( ";
                        } else if (price_rule[v].time == 12) {
                                time = "一年 ( ";
                        } else if (price_rule[v].time == 24) {
                                time = "两年 ( ";
                        } else if (price_rule[v].time == 36) {
                                time = "三年 ( ";
                        } else {
                                time = "个月 ( ";
                        }
                        options.push(<span className="pay-font1">{time + price_rule[v].price + "元 )"}</span>)
                }
                //付费专栏，选择订阅时长
                ActionSheet.showActionSheetWithOptions({
                        options: options,
                        maskClosable: true
                },
                        (buttonIndex) => {
                                if (buttonIndex == -1) return false;
                                that.setState({
                                        order_total_amount: price_rule[buttonIndex].price,
                                        curent_type: buttonIndex,
                                        coupon_amount: 0,
                                                coupon_title: '',
                                                coupon_id:''

                                })
                        });

        }
        // 选择优惠券
        change_coupon() {
                let that = this;
                let coupon_list = that.state.data.coupon_list;
                let options = [];
                let current_coupon_data=[]
                // 请先选择订阅类型
                if (that.state.curent_type == -1) {
                        fn.toast("请先选择订阅类型");
                        return false;
                }
                options.push(<span className="pay-font1">不使用优惠券</span>)
                for (const v in coupon_list) {
                        if(coupon_list[v].limit_price<=that.state.order_total_amount){
                                options.push(<span className="pay-font1">{coupon_list[v].title}</span>)
                                current_coupon_data.push(coupon_list[v])
                        }
                        
                }
                that.setState({
                        current_coupon_data:current_coupon_data
                })

                //付费专栏，选择订阅时长
                ActionSheet.showActionSheetWithOptions({
                        options: options,
                        maskClosable: true
                },
                        (buttonIndex) => {
                                if (buttonIndex == -1) return false;
                                if (buttonIndex == 0) {
                                        // 不使用优惠券
                                        that.setState({
                                                coupon_amount: 0,
                                                coupon_title: '',
                                                coupon_id:''
                                        })
                                        return false;
                                }
                                // 使用优惠券
                                // buttonIndex--;
                                // console.log(buttonIndex)
                                let coupon_amount = parseFloat(that.state.current_coupon_data[buttonIndex-1].amount);
                                that.setState({
                                        coupon_amount: coupon_amount,
                                        coupon_title: that.state.current_coupon_data[buttonIndex-1].title,
                                        coupon_id: that.state.current_coupon_data[buttonIndex-1].id
                                })
                                // that.setState({
                                //         curent_type: buttonIndex
                                // })
                        });

        }
        // 是否使用余额抵扣
        change_balance_type() {
                let that = this;
                let balance = that.state.data.balance;
                let options = [];
                // 请先选择订阅类型
                if (that.state.curent_type == -1) {
                        fn.toast("请先选择订阅类型");
                        return false;
                }
                options.push(<span className="pay-font1">是</span>)
                options.push(<span className="pay-font1">否</span>)


                //付费专栏，选择订阅时长
                ActionSheet.showActionSheetWithOptions({
                        options: options,
                        maskClosable: true
                },
                        (buttonIndex) => {
                                if (buttonIndex == -1) return false;
                                if (buttonIndex == 0) {
                                        that.setState({
                                                use_balance: true
                                        })
                                } else if (buttonIndex == 1) {
                                        that.setState({
                                                use_balance: false
                                        })
                                }

                        });
        }
        // 购买专栏
        buy_course() {
                let that = this;
                let coupon_list = that.state.data.coupon_list;
                let options = [];
                // 请先选择订阅类型
                if (that.state.curent_type == -1) {
                        fn.toast("请先选择订阅类型");
                        return false;
                }
                let data = {};
                data.token = that.props.token;
                data.coupon_id = that.state.coupon_id;
                data.type = that.state.curent_type;
                data.s = that.props.s;
                data.isShowLoading = false;
                data.use_balance = that.state.use_balance;
                data.course_id = that.props.course_id;

                Axios.ajax({
                        url: '/h5_precreate.php',
                        data: data
                }).
                        then((res) => {
                                if (res.hasOwnProperty('data')) {
                                        fn.wxpay(res.data).then((res) => {
                                                that.setState({
                                                        has_pay: true
                                                });
                                        })
                                }
                        })
        }
        // 购买专栏
        learn_course() {
                let that = this;
               
                let data = {};
                data.token = that.props.token;
                data.coupon_id = that.state.data.coupon_list[0].id;
                data.s = that.props.s;
                data.use_learn=1;
                data.isShowLoading = false;
                data.course_id = that.props.course_id;

                Axios.ajax({
                        url: '/h5_precreate.php',
                        data: data
                }).
                        then((res) => {
                                if (res.hasOwnProperty('data')) {
                                        fn.wxpay(res.data).then((res) => {
                                                that.setState({
                                                        has_pay: true
                                                });
                                        })
                                }
                        })
        }
        render() {
                let that = this;
                let course_order = that.state.data;
                if (this.props.shopinfo) { hiden_wechat_menu(this.props.shopinfo); }

                if(that.state.data&&that.state.data.mobile==""){
                        return (<Redirect to='/bind_mobile' />)
                }

                if (!this.props.course_id) {
                        return <Redirect to="/main" />
                }
                if (that.state.has_pay) {
                        return <Redirect to="/course_detail" />
                }
                let subscribe_view = null;// 订阅类型组件
                let order_amount_view = null;// 订单金额组件
                let pay_amount_view = null;// 实付金额组件
                let coupon_view = null; //优惠券组件
                let balance_view = null; //余额组件

                let order_total_amount = that.state.order_total_amount;
                let coupon_amount = that.state.coupon_amount;
                let balance = that.state.data.balance;
                let use_balance_amount = balance;//抵扣余额
                let pay_amount = order_total_amount;
                let share_benefit_amount=order_total_amount*0.02;

                // 优惠券组件
                if (course_order.coupon_list && course_order.coupon_list.length == 0) {
                        coupon_view = <text className="order-value-text">暂无</text>
                } else {
                        coupon_view = <text className="order-value-text" onClick={this.change_coupon.bind(this)}>{that.state.coupon_amount > 0 ? that.state.coupon_title : '请选择优惠券'}</text>
                }
                // 余额组件
                if (course_order.balance <= 0) {
                        balance_view = <text className="order-value-text">暂无可用余额</text>
                } else {
                        balance_view = <text className="order-value-text" onClick={this.change_balance_type.bind(this)}>{that.state.use_balance ? '是' : '否'}</text>
                }


                // 订阅类型
                if (that.state.curent_type == -1) {
                        subscribe_view = <text className="order-value-text">请选择订阅类型</text>
                } else {
                        let subscribe_time = course_order.price_rule[that.state.curent_type].time;
                        let subscribe_label = "";
                        if (subscribe_time == 3) {
                                subscribe_label = "一个季";
                        } else if (subscribe_time == 6) {
                                subscribe_label = "半年";
                        } else if (subscribe_time == 12) {
                                subscribe_label = "一年";
                        } else if (subscribe_time == 24) {
                                subscribe_label = "两年";
                        } else if (subscribe_time == 36) {
                                subscribe_label = "三年";
                        } else {
                                subscribe_label = subscribe_time + "个月";
                        }
                        // 订阅类型
                        subscribe_view = <text className="order-value-text">{subscribe_label}</text>
                        // 订单金额
                        order_amount_view = <text className="order-value-text padding-right08  price-font1">￥{order_total_amount}</text>


                        if (that.state.coupon_id) {
                                // 使用优惠券
                                if (pay_amount > coupon_amount) {
                                        // 支付金额大于优惠金额，全部抵扣
                                        pay_amount =( pay_amount*100 - coupon_amount*100)/100;

                                } else {
                                        // 支付金额小于等于优惠金额,抵扣至0.01
                                        coupon_amount =  (pay_amount*100 - 0.01*100)/100;
                                        pay_amount = 0.01;

                                }

                        }
                        if (that.state.use_balance) {
                                // 抵扣余额
                                if (pay_amount > balance) {
                                        // 支付金额大于余额，全部抵扣
                                        pay_amount = (pay_amount*100 - balance*100)/100;
                                        use_balance_amount = balance;//抵扣金额
                                } else {
                                        // 支付金额小于等于余额,抵扣至0.01
                                        use_balance_amount = (pay_amount*100 - 0.01*100)/100;
                                        pay_amount = 0.01;

                                }

                        }
                        if(that.state.data.share_benefit==1&&share_benefit_amount>0){
                                pay_amount=pay_amount-share_benefit_amount
                        }
                        // 实付金额
                        pay_amount_view = <text className="order-value-text padding-right08  price-font1">￥{pay_amount}</text>

                }

                if(this.state.learn_coupon_id!=''){
                        return(
                                <div >
                                <div className="course-order-list-view" >
                                        <div className="course-wrap-left">
                                                <img src={course_order.course_cover} className="course-cover-icon" />
                                        </div>
                                        <div className="course-wrap-right">
                                                <div className="course-title">{course_order.title} </div>
                                                {
                                                        course_order.tag != null ? <div className="course-description">{course_order.tag}</div> : ''
                                                }

                                        </div >
                                </div>

                                <div className="order-row-view">
                                        <text className="order-label-text">订阅类型</text>
                                        <div className="order-row-wrap-view" >
                                                
                                                <text className="order-value-text padding-right08">用户体验</text>
                                        </div>
                                </div>

                                {course_order.coupon_list && course_order.coupon_list.length >0?<div className="order-row-view">
                                        <text className="order-label-text">订单总金额</text>
                                        <div className="order-row-wrap-view">
                                                <text className="order-value-text padding-right08  price-font1">￥{course_order.coupon_list[0].amount}</text>
                                        </div>
                                </div>:''}
                                {course_order.coupon_list && course_order.coupon_list.length >0?<div className="order-row-view">
                                        <text className="order-label-text">使用体验券</text>
                                        <div className="order-row-wrap-view" >
                                                <text className="order-value-text padding-right08">{course_order.coupon_list[0].title}</text>
                   
                                        </div>
                                </div>:''}
                                
                   
                             

                                {course_order.coupon_list && course_order.coupon_list.length >0?<div className="order-row-view">
                                        <text className="order-label-text">实付金额</text>
                                        <div className="order-row-wrap-view">
                                                
                                                <text className="order-value-text padding-right08  price-font1">￥{course_order.coupon_list[0].amount}</text>
                                        </div>
                                </div>:''}
                                <div className="system-hint-view">
                                        ps：使用余额或优惠券时，最多抵扣实付金额至0.01元哦
                                </div>


                                <div className="order-pay-button" onClick={this.learn_course.bind(this)}>立即支付</div>

                        </div >
 
                        )
                }



                return (
                        <div >
                                <div className="course-order-list-view" >
                                        <div className="course-wrap-left">
                                                <img src={course_order.course_cover} className="course-cover-icon" />
                                        </div>
                                        <div className="course-wrap-right">
                                                <div className="course-title">{course_order.title} </div>
                                                {
                                                        course_order.tag != null ? <div className="course-description">{course_order.tag}</div> : ''
                                                }

                                        </div >
                                </div>

                                <div className="order-row-view">
                                        <text className="order-label-text">订阅类型</text>
                                        <div className="order-row-wrap-view" onClick={this.change_type.bind(this)}>
                                                {subscribe_view}
                                                <img src="/assets/arrow_right.png" className="arrow-right-style" />
                                        </div>
                                </div>

                                <div className="order-row-view">
                                        <text className="order-label-text">订单总金额</text>
                                        <div className="order-row-wrap-view">
                                                {order_amount_view}
                                        </div>
                                </div>
                                {course_order.coupon_list && course_order.coupon_list.length >0?<div className="order-row-view">
                                        <text className="order-label-text">使用优惠券</text>
                                        <div className="order-row-wrap-view" >
                                                {coupon_view}
                                                <img src="/assets/arrow_right.png" className="arrow-right-style" />
                                        </div>
                                </div>:''}
                                
                                <div className="order-row-view">
                                        <text className="order-label-text">余额抵扣( {that.state.data.balance}元 )</text>
                                        <div className="order-row-wrap-view">
                                                {balance_view}
                                                <img src="/assets/arrow_right.png" className="arrow-right-style" />
                                        </div>
                                </div>
                                {that.state.data.share_benefit == 1&&share_benefit_amount>0 ? <div className="order-row-view">
                                        <text className="order-label-text">通过{that.state.data.share_nickname}推荐，此单享受98折优惠</text>
                                        <div className="order-row-wrap-view">
                                        <text className="order-value-text padding-right08  "><span className="normal-font2">省</span> <span className="price-font2">￥{share_benefit_amount}</span></text>
                                        </div>
                                </div> : ''}
                                {that.state.coupon_amount > 0 ? <div className="order-row-view">
                                        <text className="order-label-text">优惠券抵扣</text>
                                        <div className="order-row-wrap-view">
                                                <text className="order-value-text padding-right08  price-font2">￥{coupon_amount}</text>
                                        </div>
                                </div> : ''}
                                {that.state.use_balance ? <div className="order-row-view">
                                        <text className="order-label-text">余额抵扣</text>
                                        <div className="order-row-wrap-view">
                                                <text className="order-value-text padding-right08  price-font2">￥{use_balance_amount}</text>
                                        </div>
                                </div> : ''}

                                <div className="order-row-view">
                                        <text className="order-label-text">实付金额</text>
                                        <div className="order-row-wrap-view">
                                                {pay_amount_view}
                                        </div>
                                </div>
                                <div className="system-hint-view">
                                        ps：使用余额或优惠券时，最多抵扣实付金额至0.01元哦
                                </div>


                                <div className="order-pay-button" onClick={this.buy_course.bind(this)}>立即支付</div>

                        </div >


                );
        }
}
const mapStateToProps = state => {

        return {
                token: state.token, shopinfo: state.shopinfo,
                course_id: state.course_id,
                learn_coupon_id:state.learn_coupon_id,
                s: state.s
        }
};
export default connect(mapStateToProps)(Pay_order)