import React from 'react'
import { connect } from "react-redux"
import Axios from '../../axios/index'
import { MAIN_SELECTED, VIDEO_SELECTED, STUDY_SELECTED, PERSON_SELECTED } from '../../config/footer'
import { SearchBar, Flex, WhiteSpace, WingBlank } from 'antd-mobile';
import './index.less'
import { set_course_id, set_dispatch_user_id } from '../../redux/action'
import { NavLink } from 'react-router-dom'
import { hiden_wechat_menu, show_wechat_menu, toast } from "../../utils/index"
import * as fn from '../../utils/index'
class Promot extends React.Component {
        state = {
                headimage: '',
                nickname: '',
                level_one_count: 0,
                level_two_count: 0,
                show_gate: false,
                order_amount: 0,
                order_count: 0,
                is_pay: false,
                share_one_rate: "",
                share_two_rate: "",
                super_share_one_rate: "",
                super_share_two_rate: "",
                show_more_index: -1,
                course_list: []
        }
        componentDidMount() {
                let that = this;
                console.log(this.props.shopinfo);
                if (this.props.shopinfo) { hiden_wechat_menu(this.props.shopinfo); }
                Axios.ajax({
                        url: '/promot.php',
                        data: {
                                token: this.props.token
                        }
                }).
                        then((res) => {
                                that.setState({
                                        nickname: res.data.userinfo.nickname,
                                        headimage: res.data.userinfo.headimage,
                                        level_one_count: res.data.level_one_count,
                                        level_two_count: res.data.level_two_count,
                                        order_amount: res.data.order_amount,
                                        order_count: res.data.order_count,
                                        course_list: res.data.course_list,
                                        share_one_rate: res.data.share_one_rate,
                                        share_two_rate: res.data.share_two_rate,
                                        super_share_one_rate: res.data.super_share_one_rate,
                                        super_share_two_rate: res.data.super_share_two_rate,
                                        is_pay: res.data.is_pay
                                })

                                if (res.data.is_pay) {
                                        // 开启分享
                                        show_wechat_menu(res.data.userinfo.nickname, that.props.shopinfo);
                                }
                        })

        }
        // 展开更多
        show_more(index) {
                this.setState({
                        show_more_index: index
                })
        }
        // 收起更多
        hiden_more() {
                this.setState({
                        show_more_index: -1
                })
        }
        // 跳转到分销用户列表
        goto_distribute_user(index) {
                console.log(index)
                let that = this;
                const { dispatch } = this.props;
                dispatch(set_dispatch_user_id(index));
        }
        // 显示分享引导
        show_share() {
                let that = this;
                if (!this.state.is_pay) {
                        // 未订阅专栏不可推广
                        toast('请先订阅任意付费专栏后，再进行推广！', 2);
                        return false;
                }
                if (that.state.is_pay) {
                        // 开启分享
                        show_wechat_menu(that.state.nickname, that.props.shopinfo);
                }
                this.setState({
                        show_gate: true
                })
        }
        // 跳转到专栏详情
        goto_course_detail(course_id) {
                const { dispatch } = this.props;
                dispatch(set_course_id(course_id));

        }
        // 隐藏分享引导
        hide_share() {
                this.setState({
                        show_gate: false
                })
        }
        // 提现
        withdraw() {
                console.log("提现")
        }
        render() {
                let that = this;
                let nickname = that.state.nickname;
                let headimage = that.state.headimage;
                let course_list_view = null;

                let show_gate = that.state.show_gate;

                let share_one_rate = that.state.share_one_rate * 0.01;
                let share_two_rate = that.state.share_two_rate * 0.01;
                // 高级推广比例
                let super_share_one_rate = that.state.super_share_one_rate * 0.01;
                let super_share_two_rate = that.state.super_share_two_rate * 0.01;
                if (this.state.course_list) {
                        course_list_view = this.state.course_list.map((item, index) => {

                                let price_rule = item.price_rule;
                                let time = '';
                                let one_price = fn.save_multi(price_rule[0].price, share_one_rate);
                                let two_price = fn.save_multi(price_rule[0].price, share_two_rate);
                                let super_one_price = fn.save_multi(price_rule[0].price, super_share_one_rate)
                                let super_two_price = fn.save_multi(price_rule[0].price, super_share_two_rate)
                                if (price_rule[0].time == 3) {
                                        time = "一季";
                                } else if (price_rule[0].time == 6) {
                                        time = "半年";
                                } else if (price_rule[0].time == 12) {
                                        time = "一年";
                                } else if (price_rule[0].time == 24) {
                                        time = "两年";
                                } else if (price_rule[0].time == 36) {
                                        time = "三年";
                                } else {
                                        time = price_rule[0].time + "个月";
                                }
                                let price_rule_view = null;
                                if (price_rule.length > 1) {
                                        price_rule_view = price_rule.map((item, index) => {
                                                // if (index == 0) return null;
                                                let item_time = '';
                                                if (item.time == 3) {
                                                        item_time = "一季";
                                                } else if (item.time == 6) {
                                                        item_time = "半年";
                                                } else if (item.time == 12) {
                                                        item_time = "一年";
                                                } else if (item.time == 24) {
                                                        item_time = "两年";
                                                } else if (item.time == 36) {
                                                        item_time = "三年";
                                                } else {
                                                        item_time = item.time + "个月";
                                                }
                                                let tmp_one_price = fn.save_multi(item.price, share_one_rate);

                                                let tmp_two_price = fn.save_multi(item.price, share_two_rate);

                                                let tmp_super_one_price = fn.save_multi(item.price, super_share_one_rate)
                                                let tmp_super_two_price = fn.save_multi(item.price, super_share_two_rate)

                                                return (
                                                        <div>
                                                                <div className="promot-course-row-view">
                                                                        <text className="promot-course-font2">普通直接推广({item_time})：<span className="promot-course-font3">￥{tmp_one_price}</span></text>
                                                                        <text className="promot-course-font2">普通间接推广({item_time})：<span className="promot-course-font3">￥{tmp_two_price}</span></text>

                                                                </div>
                                                                <div className="promot-course-row-view">
                                                                        <text className="promot-course-font2">高级直接推广({item_time})：<span className="promot-course-font3">￥{tmp_super_one_price}</span></text>
                                                                        <text className="promot-course-font2">高级间接推广({item_time})：<span className="promot-course-font3">￥{tmp_super_two_price}</span></text>
                                                                </div>
                                                        </div>
                                                )
                                        })
                                }
                                let show_more_view = null;
                                if (price_rule.length > 1) {
                                        // console.log(that.state.show_more_index)
                                        // console.log(index)
                                        // console.log(that.state.show_more_index===index)
                                        if (that.state.show_more_index === index) {
                                                show_more_view = <text className="promot-show-more" onClick={this.hiden_more.bind(this)}>收起</text>
                                        } else {
                                                show_more_view = <text className="promot-show-more" onClick={this.show_more.bind(this, index)}>更多</text>
                                        }
                                }

                                return (
                                        <div className="promot-course-list" >
                                                <NavLink className="promot-course-left" to="/course_detail" onClick={this.goto_course_detail.bind(this, item.id)}>
                                                        <img src={item.course_cover} className="promot-course-cover-style" />
                                                </NavLink>
                                                <div className="promot-course-right">

                                                        <div className="promot-course-wrap-view">
                                                                <div className="promot-course-wrap-left-view">
                                                                        <text className="promot-course-font1">{item.title}</text>
                                                                        {/* <text className="promot-course-font2">课程:￥{item.price}  </text> */}
                                                                </div>
                                                                <div className="promot-course-wrap-right-view" onClick={this.show_share.bind(this)}>
                                                                        推广
                                                                </div>
                                                        </div>

                                                        {price_rule.length > 1 && that.state.show_more_index === index ? price_rule_view : <div><div className="promot-course-row-view">
                                                                <text className="promot-course-font2">普通直接推广({time})：<span className="promot-course-font3">￥{one_price}</span></text>
                                                                <text className="promot-course-font2">普通间接推广({time})：<span className="promot-course-font3">￥{two_price}</span></text>   
                                                        </div><div className="promot-course-row-view">
                                                                        <text className="promot-course-font2">高级直接推广({time})：<span className="promot-course-font3">￥{super_one_price}</span></text>
                                                                        <text className="promot-course-font2">高级间接推广({time})：<span className="promot-course-font3">￥{super_two_price}</span></text>
                                                                </div></div>}
                                                        {show_more_view}
                                                </div>
                                        </div>
                                )
                        })
                }
                let share_view = (<div className={show_gate ? "share-bg-window display-flex" : "share-bg-window display-none"} onClick={this.hide_share.bind(this)}>
                        <div className="share-bg-level-one"></div>
                        <img src="/assets/share_icon_bg.png" className="share-icon-style" />
                </div>);
                return (
                        <div className="promot-container-view">
                                <div className="userinfo-header-view">
                                        <img src={headimage} class="userinfo-headimages-icon" />
                                        <text class="userinfo-nickname">{nickname}</text>
                                </div>
                                <div className="userinfo-content-view">
                                        <div className="userinfo-content-top">
                                                <div className="userinfo-content-wrap-left">
                                                        <text className="userinfo-font1">累计推广收益 ( 元 )</text>
                                                        <text className="userinfo-font3">{that.state.order_amount}</text>
                                                </div>
                                                <div className="userinfo-content-wrap-right">
                                                        <NavLink to="/withdraw"><div className="userinfo-withdraw-view" >提现中心</div>  </NavLink>
                                                </div>
                                        </div>
                                        <div className="userinfo-content-down">
                                                <NavLink to="/distribute_user" className="user-content-down-row border-right" onClick={this.goto_distribute_user.bind(this, 0)}>
                                                        <text className="userinfo-font1">直接推广员(人)</text>
                                                        <text className="userinfo-font2">{that.state.level_one_count}</text>
                                                </NavLink>
                                                <NavLink to="/distribute_user" className="user-content-down-row border-right" onClick={this.goto_distribute_user.bind(this, 1)}>
                                                        <text className="userinfo-font1">间接推广员(人)</text>
                                                        <text className="userinfo-font2">{that.state.level_two_count}</text>
                                                </NavLink>
                                                <NavLink to="/distribute_order" className="user-content-down-row ">
                                                        <text className="userinfo-font1">推广总订单(笔)</text>
                                                        <text className="userinfo-font2">{that.state.order_count}</text>
                                                </NavLink>
                                        </div>
                                </div>
                                {/* 专栏推广 */}
                                <div className="promot-header-label-view">
                                        推广商品
                                </div>
                                {course_list_view}
                                {share_view}
                                <div className="footer-hint-view">
                                        —— 亲，已经到底了哦 ——
                                </div>
                        </div>
                );
        }
}
const mapStateToProps = state => {
        return {
                token: state.token, shopinfo: state.shopinfo
        }
};
export default connect(mapStateToProps)(Promot)