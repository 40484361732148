import React from 'react'
import { connect } from "react-redux"
import Axios from '../../axios/index'
import { MAIN_SELECTED, VIDEO_SELECTED, STUDY_SELECTED, PERSON_SELECTED } from '../../config/footer'
import { SearchBar, Flex, WhiteSpace, WingBlank } from 'antd-mobile';
import './index.less'
import { NavLink } from 'react-router-dom'
import { hiden_wechat_menu } from "../../utils/index"
class Distribute_user extends React.Component {
        state = {
                active_tab:0,
                level_one_data: [],
                level_two_data: []
        }
        change_tab(v) {
                this.setState({
                        active_tab: v
                })
        }
        componentDidMount() {
                let that = this;
                Axios.ajax({
                        url: '/get_children_user.php',
                        data: {
                                token: this.props.token
                        }
                }).
                        then((res) => {
                                that.setState({
                                        level_one_data: res.data.level_one_data,
                                        level_two_data: res.data.level_two_data
                                })
                        })
                that.setState({
                        active_tab:that.props.dispatch_user_id
                })

        }
        render() {
                let level_one_data = this.state.level_one_data;
                let level_two_data = this.state.level_two_data;
                let level_one_view = null;
                let level_two_view = null;
                if (this.props.shopinfo) { hiden_wechat_menu(this.props.shopinfo); }
                // 直接成员数据
                if (level_one_data) {
                        level_one_view = this.state.level_one_data.map((item) => {
                                return (
                                        <div className="distribute-user-list">
                                                <div className="distribute-user-left-view">
                                                        <img src={item.headimage} className="distribute-user-headimage" />
                                                </div>
                                                <div className="distribute-user-right-view">
                                                        <text className="distribute-user-font1">直接推广员昵称：{item.nickname}</text>
                                                        <text className="distribute-user-font1">累计订阅金额(元)：<span className="distribute-user-font2">{item.total_amount}</span></text>
                                                        <text className="distribute-user-font1">累计订阅订单(笔)：<span className="distribute-user-font2">{item.total_count}</span></text>
                                                </div>
                                        </div>
                                        
                                )

                        })
                }
                // 间接成员数据
                if (level_two_data) {
                        level_two_view = this.state.level_two_data.map((item) => {
                                return (
                                        <div className="distribute-user-list">
                                                <div className="distribute-user-left-view">
                                                        <img src={item.headimage} className="distribute-user-headimage" />
                                                </div>
                                                <div className="distribute-user-right-view">
                                                        <text className="distribute-user-font1">间接推广员昵称：{item.nickname}</text>
                                                        <text className="distribute-user-font1">累计订阅金额(元)：<span className="distribute-user-font2">{item.total_amount}</span></text>
                                                        <text className="distribute-user-font1">累计订阅订单(笔)：<span className="distribute-user-font2">{item.total_count}</span></text>
                                                </div>
                                        </div>
                                )

                        })
                }

                return (
                        <div>
                                <div className="distribute-nav-view">
                                        <div className={this.state.active_tab == 0 ? 'nav-row-view nav-active' : 'nav-row-view'} onClick={this.change_tab.bind(this, 0)}>直接推广员</div>
                                        <div className={this.state.active_tab == 1 ? 'nav-row-view nav-active' : 'nav-row-view'} onClick={this.change_tab.bind(this, 1)}>间接推广员</div>
                                </div>
                                {this.state.active_tab == 0?level_one_view:level_two_view}
                                
                        </div>

                );
        }
}
const mapStateToProps = state => {

        return {
                token: state.token, shopinfo: state.shopinfo,
                dispatch_user_id:state.dispatch_user_id

        }
};
export default connect(mapStateToProps)(Distribute_user)