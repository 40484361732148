import React, { Component } from 'react';

import { HashRouter, Route, Switch, Redirect } from 'react-router-dom'
class App extends Component {
  
  render() {
    

    return (
      <div>
        
        {this.props.children}

      </div>
    );
  }
}

export default App;
