import React from 'react'
import { connect } from "react-redux"
import Main from './pages/main'
import Person from './pages/person'
import Study from './pages/study'
import Course_detail from './pages/course_detail'
import Article_detail from './pages/article_detail'
import Course from './pages/course'
import App from './App'
import Withdraw from "./pages/withdraw"
import Distribute_user from "./pages/distribute_user"
import Distribute_order from "./pages/distribute_order"
import Apply_withdraw from "./pages/apply_withdraw"
import { set_token, set_shop_info, set_share_id,set_course_content_id ,set_course_id} from './redux/action/index'
import Axios from './axios/index'
import { Tabs, Modal, Toast, ActionSheet } from 'antd-mobile';
import { HashRouter, Route, Switch, Redirect, NavLink } from 'react-router-dom'
import { hiden_wechat_menu, getUrlParam } from "./utils/index"
import Bind_mobile  from "./pages/bind_mobile"
import Promot from "./pages/promot"
import MyOrder from "./pages/myorder"
import Get_coupon from './pages/get_coupon'
import Coupon from './pages/coupon'
import Pay_order from './pages/pay_order'
import Default from './pages/default'
import Address from './pages/address'

class IRouter extends React.Component {
    state = {
        shopinfo: null
    }

    componentDidMount() {
        let token = getUrlParam("token")
        let s = getUrlParam("s")
        let a = getUrlParam("a")
        let v = getUrlParam("v")
        const { dispatch } = this.props;

        if (token) {
            dispatch(set_token(token));
            this.init_shop_info(token);
            this.init_wx_jssdk(token);
        }
        // 设置分享者id
        if (s) {
            dispatch(set_share_id(s));
        }
        // 设置文章id
        if (a) {
            dispatch(set_course_content_id(a));
        }
        // 设置专栏id
        if (v) {

            dispatch(set_course_id(v));
        }

    }
    // 初始化商家数据
    init_shop_info(token) {
        let that = this;
        Axios.ajax({
            url: '/h5_shopinfo.php',
            data: {
                token: token
            }
        }).
            then((res) => {

                const { dispatch } = that.props;

                dispatch(set_shop_info(res.data));
                that.setState({
                    shopinfo: res.data
                })
                window.wx.ready(function () {

                    hiden_wechat_menu(res.data);


                    hiden_wechat_menu();
                });

                document.title = res.data.company;

            })

    }
    // 初始化sdk
    init_wx_jssdk(token) {
        let js_url = window.location.href.split('#')[0];
        let that = this;
        Axios.ajax({
            url: '/h5_jssdk.php',
            data: {
                token: token,
                js_url: js_url
            }
        }).
            then((res) => {

                let param = res.data;

                window.wx.config({
                    debug: false,
                    appId: param.appId,
                    timestamp: param.timestamp,
                    nonceStr: param.nonceStr,
                    signature: param.signature,
                    jsApiList: [
                        'closeWindow',
                        'updateAppMessageShareData',
                        'updateTimelineShareDatanpm',
                        'onMenuShareAppMessage', //旧的接口，即将废弃
                        'onMenuShareTimeline', //旧的接口，即将废弃
                        'showMenuItems',
                        'hideAllNonBaseMenuItem'
                        // 所有要调用的 API 都要加到这个列表中
                    ]
                });

                window.wx.error(function (res) {
                    // Toast.info("失败");

                    console.log(res)
                    // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
                });
            })
    }
    render() {
        let p = getUrlParam("p");
        let default_path = "/main";
        let shopinfo = this.state.shopinfo;
        if (p) {
            default_path = p;
        }

        if (this.props.token == false) {
            return (<div></div>)
        }

        if (shopinfo) {
            // Toast.info(shopinfo.s);
            hiden_wechat_menu(shopinfo);
        }
        return (
            
            <HashRouter>
              <App>
                    <Switch>
                        <Route path='/distribute_user' component={Distribute_user} />
                        <Route path='/promot' component={Promot} />
                        <Route path='/bind_mobile' component={Bind_mobile} />
                        <Route path='/main' component={Main} />
                        <Route path='/default' component={Default} />
                        <Route path='/person' component={Person} />
                        <Route path='/course' component={Course} />
                        <Route path='/study' component={Study} />
                        <Route path='/distribute_order' component={Distribute_order} />
                        <Route path='/article_detail' component={Article_detail} />
                        <Route path='/withdraw' component={Withdraw} />
                        <Route path='/course_detail' component={Course_detail} />
                        <Route path='/apply_withdraw' component={Apply_withdraw} />
                        <Route path='/myorder' component={MyOrder} />
                        <Route path='/get_coupon' component={Get_coupon} />
                        <Route path='/coupon' component={Coupon} />
                        <Route path='/pay_order' component={Pay_order} />
                        <Route path='/address' component={Address} />
                        <Redirect to={default_path} />
                    </Switch>
                    </App>
               
            </HashRouter>
        );
    }
}
const mapStateToProps = state => {
    return {
        token: state.token,
        shopinfo: state.shopinfo
    }
};
export default connect(mapStateToProps)(IRouter)