import React from 'react'
import { connect } from "react-redux"
import Axios from '../../axios/index'
import { Tabs, Modal, Toast, ActionSheet } from 'antd-mobile';
import { set_course_id, set_course_cate_id, set_course_search_title } from '../../redux/action'
import './index.less'
import ReactPlayer from 'react-player'
import { hiden_wechat_menu, share_article, toast } from "../../utils/index"

import { HashRouter, Route, Switch, Redirect, NavLink } from 'react-router-dom'
import * as fn from '../../utils/index'
const tabs = [
        { title: '详情' },
        { title: '目录' }
];
const prompt = Modal.prompt;
class Article_detail extends React.Component {
        state = {
                nickname: '',
                show_gate: false,
                content_data: {}
        }

        componentDidMount() {
                if (this.props.shopinfo) { hiden_wechat_menu(this.props.shopinfo); }
                // console.log("嘻嘻")
                // console.log(this.props.course_content_id)
                if(this.props.course_content_id!=''){
                        this.initData();
                }
                
        }
        show_share() {
                let that = this;
                if (!this.state.content_data.is_pay) {
                        // 未订阅专栏不可推广
                        fn.toast('请先订阅任意付费专栏后，再进行推广！', 2);
                        return false;
                }
                if (that.state.content_data.is_pay) {
                        // 开启分享
                        share_article(that.state.nickname, that.props.shopinfo, '/article_detail', that.state.content_data.title, that.state.content_data.id);
                }
                this.setState({
                        show_gate: true
                })
        }
        // 隐藏分享引导
        hide_share() {
                this.setState({
                        show_gate: false
                })
        }
        initData() {
                if (!this.props.course_content_id) return false;
                let that = this;
                Axios.ajax({
                        url: '/article_detail.php',
                        data: {
                                token: this.props.token,
                                course_content_id: this.props.course_content_id
                        }
                }).
                        then((res) => {

                                that.setState({
                                        content_data: res.data,
                                        nickname: res.data.nickname
                                })
                                // 设置当前专栏id
                                const { dispatch } = that.props;
                                dispatch(set_course_id(res.data.course_id));
                                if (res.data.is_pay) {
                                        // 付费用户开启分享
                                        share_article(res.data.nickname, that.props.shopinfo, '/article_detail', res.data.title, res.data.id);
                                }

                        })
        }
        render() {
                let that = this;
                // if (!this.props.course_content_id) {
                //         return (<Redirect to="/main" />)
                // }
                let share_view = (<div className={this.state.show_gate ? "share-bg-window display-flex" : "share-bg-window display-none"} onClick={this.hide_share.bind(this)}>
                        <div className="share-bg-level-one"></div>
                        <img src="/assets/share_icon_bg.png" className="share-icon-style" />
                </div>);
                return (
                        <div className="article-content-view">
                                <div className="article-content-info-view">

                                        <div className="course-detail" dangerouslySetInnerHTML={{ __html: that.state.content_data.content }} />
                                        <div className="footer-hint-view">
                                                —— 亲，已经到底了哦 ——
                                        </div>
                                </div>

                                <div className="blanket-view"></div>
                                <div className="article-footer-view">

                                        <NavLink to="/main"><div className="article-share-view" >返回首页</div></NavLink>
                                        <NavLink to="/course_detail"><div className="article-share-view" >其他课程</div></NavLink>
                                        <div className="article-share-view" onClick={this.show_share.bind(this)}>分享</div>
                                </div>

                                {share_view}
                        </div>

                );
        }
}
const mapStateToProps = state => {

        return {
                token: state.token,
                shopinfo: state.shopinfo,
                s: state.s,
                course_id: state.course_id,
                course_content_id: state.course_content_id
        }
};
export default connect(mapStateToProps)(Article_detail)