import React from 'react'
import { connect } from "react-redux"
import Axios from '../../axios/index'
import { MAIN_SELECTED, VIDEO_SELECTED, STUDY_SELECTED, PERSON_SELECTED } from '../../config/footer'
import { SearchBar, Flex, WhiteSpace, WingBlank, Modal } from 'antd-mobile';
import './index.less'
import { NavLink, Redirect } from 'react-router-dom'
import * as fn from '../../utils/index'
import { hiden_wechat_menu } from "../../utils/index"
const prompt = Modal.prompt;
class Person extends React.Component {
        state = {
                headimage: '',
                nickname: '',
                mobile: '',
                goto_bind_mobile: false
        }
        // 显示修改密码弹窗
        show_modify_view() {
                let that = this;
                prompt(
                        '修改密码',
                        '请输入新密码',
                        [
                                {
                                        text: '取消',
                                        onPress: value => new Promise((resolve) => {
                                                resolve();
                                        }),
                                },
                                {
                                        text: '提交',
                                        onPress: (pswd) => new Promise((resolve, reject) => {
                                                if (pswd.length < 4 || pswd.length > 16) {
                                                        fn.toast('新密码需为4-16位任意字符');
                                                        reject();
                                                        return false;
                                                }
                                                that.modify_pswd(pswd, function () {
                                                        resolve();
                                                })
                                        }),
                                },
                        ]
                        ,
                        'secure-text',
                        '',
                        ['请输入4-16位任意字符']
                )
        }
        // 修改密码
        modify_pswd(pswd, callback) {
                let that = this;
                let data = {};
                data.pswd = pswd;
                data.isShowLoading = false;
                data.token = this.props.token;
                Axios.ajax({
                        url: '/modify_pswd.php',
                        data: data
                }).
                        then((res) => {
                                fn.toast("修改成功");
                                callback();
                        })
        }
        componentDidMount() {
                let that = this;
                Axios.ajax({
                        url: '/userinfo.php',
                        data: {
                                token: this.props.token
                        }
                }).
                        then((res) => {
                                that.setState({
                                        nickname: res.data.nickname,
                                        headimage: res.data.headimage,
                                        mobile: res.data.mobile
                                })
                                if (!res.data.mobile) {
                                        fn.toast("请先绑定手机号");
                                        this.setState({
                                                goto_bind_mobile: true
                                        })
                                }
                        })

        }
        render() {
                let headimage = this.state.headimage;
                let nickname = this.state.nickname;
                let mobile = this.state.mobile;
                mobile= mobile.slice(0, 5) +"****"+mobile.slice(9);;
            
                if (this.state.goto_bind_mobile) {
                        return (<Redirect to='/bind_mobile' />)
                }
                if (this.props.shopinfo) { hiden_wechat_menu(this.props.shopinfo); }
                return (
                        <div>
                                <div class="user-view">
                                        <img src={headimage} class="headimages-icon" />


                                </div>
                                {/* <div class="person-content-view">
                                        <NavLink class="person-module-view" to="/promot">
                                                <img src="/assets/share_pic.png" class="module-icon" />
                                                <text class="module-label">推广中心</text>
                                        </NavLink>
                                        <NavLink class="person-module-view" to="/withdraw">
                                                <img src="/assets/withdraw.png" class="module-icon" />
                                                <text class="module-label">佣金提现</text>
                                        </NavLink>
                                </div> */}
                                <div className="person-container-view">
                                        <div className="person-list-view">
                                                <div className="person-list-row-view">
                                                        <img src="/assets/user_nickname.png" class="person-list-module-icon" />
                                                        <div className="row-wrap-view">
                                                                <text className="person-list-module-label">用户昵称</text>
                                                                <text className="person-list-value">{nickname}</text>
                                                        </div>

                                                </div>
                                                <div className="person-list-row-view">

                                                        <img src="/assets/mobile_icon.png" class="person-list-module-icon" />
                                                        <div className="row-wrap-view">
                                                                <text className="person-list-module-label">绑定手机号</text>
                                                                <text className="person-list-value">{mobile}</text>
                                                        </div>

                                                </div>
                                
                                                <NavLink className="person-list-row-view" to="/address">
                                                        <img src="/assets/address_icon.png" class="person-list-module-icon" />
                                                        <div className="row-wrap-view">
                                                                <text className="person-list-module-label">收货地址</text>
                                                                <img src="/assets/arrow_right.png" class="arrow-right-icon" />
                                                        </div>
                                                </NavLink>
                                                <div className="person-list-row-view" onClick={this.show_modify_view.bind(this)}>

                                                        <img src="/assets/pscode.png" class="person-list-module-icon" />
                                                        <div className="row-wrap-view">
                                                                <text className="person-list-module-label">修改密码</text>
                                                                <img src="/assets/arrow_right.png" class="arrow-right-icon" />
                                                        </div>

                                                </div>
                                                <NavLink className="person-list-row-view" to="/coupon">
                                                        <img src="/assets/coupon.png" class="person-list-module-icon" />
                                                        <div className="row-wrap-view">
                                                                <text className="person-list-module-label">我的优惠券</text>
                                                                <img src="/assets/arrow_right.png" class="arrow-right-icon" />
                                                        </div>
                                                </NavLink>
                                                <NavLink className="person-list-row-view" to="/myorder">
                                                        <img src="/assets/order_pic.png" class="person-list-module-icon" />

                                                        <div className="row-wrap-view">
                                                                <text className="person-list-module-label">我的账单</text>
                                                                <img src="/assets/arrow_right.png" class="arrow-right-icon" />
                                                        </div>
                                                </NavLink>
                                                
                                                <NavLink className="person-list-row-view" to="/promot">
                                                        <img src="/assets/share_pic.png" class="person-list-module-icon" />

                                                        <div className="row-wrap-view">
                                                                <text className="person-list-module-label">推广中心</text>
                                                                <img src="/assets/arrow_right.png" class="arrow-right-icon" />
                                                        </div>
                                                </NavLink>
                                                <NavLink className="person-list-row-view" style={{ border: 'none' }} to="/withdraw">
                                                        <img src="/assets/withdraw.png" class="person-list-module-icon" />
                                                        <div className="row-wrap-view">
                                                                <text className="person-list-module-label">佣金提现</text>
                                                                <img src="/assets/arrow_right.png" class="arrow-right-icon" />
                                                        </div>
                                                </NavLink>
                                               
                                        </div>
                                </div>

                                {PERSON_SELECTED}
                                <div className="blanket-view"></div>
                        </div>

                );
        }
}
const mapStateToProps = state => {

        return {
                token: state.token, shopinfo: state.shopinfo

        }
};
export default connect(mapStateToProps)(Person)





