import React from 'react'
import { connect } from "react-redux"
import { set_course_id } from '../../redux/action'
import { NavLink } from 'react-router-dom'
import { SearchBar, Flex, WhiteSpace, WingBlank } from 'antd-mobile';
import './index.less'
import { hiden_wechat_menu } from "../../utils/index"
import * as fn from '../../utils/index'
import Axios from '../../axios/index'

class MyOrder extends React.Component {
        state = {
                new_course: [],
                now_date: ''
        }

        componentDidMount() {
                let that = this;
                that.initData();
        }
        initData() {
                let that = this;
                Axios.ajax({
                        url: '/order.php',
                        data: {
                                token: this.props.token
                        }
                }).
                        then((res) => {
                                that.setState({
                                        new_course: res.data
                                })
                        })
                let now_date = fn.get_current_date();
                that.setState({
                        now_date: now_date
                })
        }

        render() {
                let that = this;
                if (this.props.shopinfo) { hiden_wechat_menu(this.props.shopinfo); }
                let shengque_view = null;
                if (this.state.new_course == undefined || this.state.new_course.length == 0) {
                        shengque_view = (<div className="none-order" style={{ marginTop: "100px" }}>
                                <img src="/assets/quesheng_img.png" className="pic-zhangdan-style" />
                                <text className="none-order-font">暂无账单</text>
                        </div>)
                }

                let new_course = null;

                if (this.state.new_course) {
                        new_course = this.state.new_course.map((item) => {
                                if(item.type==1){
                                        return (
                                                <div className="zhangdan-view">
                                                        <div className="zhangdan-row-view">
                                                                <div className="zhangdan-row-view-left">
                                                                        <text className="zhangdan-font1">订阅专栏《{item.title}》</text>
                                                                        <text className="zhangdan-font2">{item.payment_time}</text>
                                                                </div>
                                                                <div className="zhangdan-row-view-right">
                                                                        <text className="zhangdan-font3">-{item.total_amount}</text>
                                                                </div>
                                                        </div>
                                                </div>
                                        )

                                }else if( item.type==2){
                                        return (
                                                <div className="zhangdan-view">
                                                        <div className="zhangdan-row-view">
                                                                <div className="zhangdan-row-view-left">
                                                                        <text className="zhangdan-font1">赠送专栏《{item.title}》订阅有效期(天)</text>
                                                                        <text className="zhangdan-font2">{item.payment_time}</text>
                                                                </div>
                                                                <div className="zhangdan-row-view-right">
                                                                        <text className="zhangdan-font4">+{item.total_amount}</text>
                                                                
                                                                </div>
                                                        </div>
                                                </div>
                                        )
                                }
                                

                        })
                }
                return (
                        <div >

                                {new_course}

                                {shengque_view?shengque_view:<div className="footer-hint-view">
                                        -- 我也是有底线的哦 --
                                </div>}
                                
              
                        </div >


                );
        }
}
const mapStateToProps = state => {

        return {
                token: state.token, shopinfo: state.shopinfo,
                s: state.s
        }
};
export default connect(mapStateToProps)(MyOrder)