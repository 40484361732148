

// 正式服
const SERVER_ROOT="https://api.dsjchaogu.com/";

// 测试服
//  const SERVER_ROOT="https://api.educate.simpleoj.cn/";

const  SERVER_PATH=SERVER_ROOT+"mini_api/";
export {SERVER_PATH,SERVER_ROOT};







// 余额抵扣

// 可提现余额
// h5   提现模块


// total_amount receiv_amount   use_balance

