import React from 'react'
import { connect } from "react-redux"
import Axios from '../../axios/index'
import { MAIN_SELECTED, VIDEO_SELECTED, STUDY_SELECTED, PERSON_SELECTED } from '../../config/footer'
import { SearchBar, Flex, List, Toast } from 'antd-mobile';
import { HashRouter, Route, Switch, Redirect, NavLink } from 'react-router-dom'
import './index.less'
import { hiden_wechat_menu } from "../../utils/index"
class Apply_withdraw extends React.Component {
        state = {
                goto_withdraw_list: false,
                old_data: {},
                bankcard: '',
                nickname: '',
                identify_card: '',
                bankname: ''
        }
        apply_withdraw() {
                console.log("申请提现")
        }
        componentDidMount() {
                let that = this;
                Axios.ajax({
                        url: '/get_backcard.php',
                        data: {
                                token: this.props.token
                        }
                }).
                        then((res) => {
                                let userinfo=res.data.userinfo;
                                that.setState({
                                        old_data:userinfo,
                                        bankcard: userinfo.bankcard,
                                        nickname: userinfo.real_name,
                                        identify_card: userinfo.identify_card,
                                        bankname: userinfo.bankname
                                })
                        })
        }
        // 监听名称变化
        change_input(e, type) {
                let value = e.target.value;
                let that = this;
                switch (type) {
                        case 'nickname':
                                that.setState({
                                        nickname: value
                                })
                                break;
                        case 'identify_card':
                                that.setState({
                                        identify_card: value
                                })
                                break;
                        case 'bankname':
                                that.setState({
                                        bankname: value
                                })
                                break;
                        case 'bankcard':
                                that.setState({
                                        bankcard: value
                                })
                                break;

                }

        }
        // 提交提现申请
        submit_withdraw() {
                if (this.state.bankcard == '' || this.state.nickname == '' || this.state.identify_card == '' || this.state.bankname == '') {
                        Toast.info('信息不能为空', 1);
                        return false;
                }
        
                let data = {};
                let that = this;
                let old_data=that.state.old_data;
                data.token = this.props.token;
                data.nickname = this.state.nickname;
                data.identify_card = this.state.identify_card;
                data.bankname = this.state.bankname;
                data.bankcard = this.state.bankcard;
                data.isShowLoading = false;
                // 未修改直接返回
                if(data.nickname==old_data.real_name&&data.identify_card==old_data.identify_card&&data.bankname==old_data.bankname&&data.bankcard==old_data.bankcard){
                        that.setState({
                                goto_withdraw_list: true
                        })
                        return false;
                }
                Axios.ajax({
                        url: '/update_bankcard.php',
                        data: data
                }).
                        then((res) => {

                                Toast.info('保存成功', 1);
                                that.setState({
                                        goto_withdraw_list: true
                                })
                        })
        }
        render() {
                if (this.props.shopinfo) { hiden_wechat_menu(this.props.shopinfo); }
                if (this.state.goto_withdraw_list == true) {
                        return (
                                <Redirect to="/withdraw" />
                        );
                }

                return (
                        <div  >

                                <div className="input-row">
                                        <text className="input-label">真实姓名</text>
                                        <input className="input-value" value={this.state.nickname} placeholder="姓名" onChange={(e) => this.change_input(e, "nickname")} />
                                </div>
                                <div className="input-row">
                                        <text className="input-label">身份证号</text>
                                        <input className="input-value" value={this.state.identify_card} onChange={(e) => this.change_input(e, "identify_card")} placeholder="18位身份证号" />
                                </div>
                                <div className="input-row">
                                        <text className="input-label">银行名称</text>
                                        <input className="input-value" value={this.state.bankname} onChange={(e) => this.change_input(e, "bankname")} placeholder="开通银行卡银行名称" />
                                </div>
                                <div className="input-row">
                                        <text className="input-label">银行卡号</text>
                                        <input className="input-value" value={this.state.bankcard} onChange={(e) => this.change_input(e, "bankcard")} placeholder="姓名对应的银行卡号" />
                                </div>
                                <div className="hint-row">
                                        * 请填写真实信息，虚假数据将会影响佣金提现!
                                </div>

                                <div className="withdraw-button" onClick={this.submit_withdraw.bind(this)}>保存</div>
                        </div >







                );
        }
}
const mapStateToProps = state => {

        return {
                token: state.token, shopinfo: state.shopinfo
        }
};

export default connect(mapStateToProps)(Apply_withdraw)