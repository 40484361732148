import JsonP from 'jsonp'
import axios from 'axios'
import { Toast } from 'antd-mobile'
import { connect } from "react-redux"
import {SERVER_PATH} from '../config/config'
export default  class Axios {
    static jsonp(options) {
        return new Promise((resolve, reject) => {
            JsonP(options.url, {
                param: 'callback'
            }, function (err, response) {
                if (response.status == 'success') {
                    resolve(response);
                } else {
                    reject(response.messsage);
                }
            })
        })
    }

    static ajax(options){
        let loading;
        if (options.data && options.data.isShowLoading !== false){
            loading = document.getElementById('loading-window');
            loading.style.display = 'flex';
        }else{
            Toast.loading('操作处理中', 10);
        }

        return new Promise((resolve,reject)=>{

            axios({
                url:options.url,
                method:'post',
                baseURL:SERVER_PATH,
                headers: {
                    'Content-type': 'multipart/form-data',
                   
                },
                timeout:10000,
                params: (options.data?options.data:{}) 
            }).then((response)=>{
                if (options.data && options.data.isShowLoading !== false) {
                    loading = document.getElementById('loading-window');
                    loading.style.display = 'none';
                }else{
                    Toast.hide();
                }

                if (response.status == '200'){
                    let res = response.data;
                   
                    if (res.errno == 0){
                        resolve(res);
                    }else{
                        Toast.info(res.errmsg,2);
                      
                    }
                }else{
                    reject(response.data);
                    loading = document.getElementById('ajaxLoading');
                    loading.style.display = 'none';
                }
            })
        });
    }
}
