import { Toast } from 'antd-mobile'
import  {SERVER_PATH} from '../config/config'
//获取参数
export function getUrlParam(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return decodeURI(r[2]); return null;
    return null;
}
export function getCaption(obj) {
    var index = obj.lastIndexOf("#");
    obj = obj.substring(index + 1, obj.length);
    //  console.log(obj);
    return obj;
}
// 隐藏微信菜单
export function hiden_wechat_menu(shopinfo = null) {
    // let path = getCaption(window.location.href);
    console.log("执行隐藏操作")
    window.wx.hideAllNonBaseMenuItem();
}
// 显示分享按钮
export function show_wechat_menu(nickname=null,shopinfo = null,path="/main",custom_title=null,article_id=null){
    //console.log(shopinfo)
    window.wx.showMenuItems({
        menuList: ["menuItem:share:appMessage", "menuItem:share:timeline"] // 要显示的菜单项，所有menu项见附录3
    });
    
    if (shopinfo) {
        let title=shopinfo.company;
        let tmp=""
        if(shopinfo.share_benefit==1){
            tmp="(点此进入可享95折优惠)"
        }
        if(custom_title){
            title=custom_title;
        }
        // //console.log(shopinfo)
        // console.log(nickname+"向您推荐 : "+ title+tmp)
        // console.log("haha")
        window.wx.updateAppMessageShareData({
            title:nickname+"向您推荐 : "+ title+tmp, // 分享标题
            desc: shopinfo.desc, // 分享描述
            link: SERVER_PATH+'h5_gate.php?u='+shopinfo.u+'&s='+shopinfo.s+'&p='+path+'&a='+article_id, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: shopinfo.pic,
            success: function () {
                // 用户点击了分享后执行的回调函数
                // Toast.info("分享成功",1);
            },
            fail: function (res) {
                // Toast.info(res.errMsg,1);

            }
        });
        window.wx.onMenuShareTimeline({
            title: nickname+"向您推荐 : "+ title+tmp, // 分享标题
            link: SERVER_PATH+'h5_gate.php?u='+shopinfo.u+'&s='+shopinfo.s+'&p='+path+'&a='+article_id, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: shopinfo.pic, // 分享图标
            success: function () {
                // 用户点击了分享后执行的回调函数
            },
        });
        window.wx.updateTimelineShareData({
            title:nickname+"向您推荐 : "+  title+tmp, // 分享标题
            imgUrl: shopinfo.pic,
            link: SERVER_PATH+'h5_gate.php?u='+shopinfo.u+'&s='+shopinfo.s+'&p='+path+'&a='+article_id, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致

            success: function () {
                // 用户点击了分享后执行的回调函数
                // Toast.info("分享成功",1);
            },
            fail: function (res) {
                // Toast.info(res.errMsg,1);

            }
        });

    }
}

// 分享文章
export function  share_article(nickname=null,shopinfo = null,path="/main",custom_title=null,article_id=null,course_id=null,tmp_pic=null){
    window.wx.showMenuItems({
        menuList: ["menuItem:share:appMessage", "menuItem:share:timeline"] // 要显示的菜单项，所有menu项见附录3
    });
    //console.log(shopinfo)
    if (shopinfo) {
        let pic=shopinfo.pic;

        if(tmp_pic){
            pic=tmp_pic;
        }
        let tmp=""
        if(shopinfo.share_benefit==1){
            tmp="(点此进入可享95折优惠)"
        }
        window.wx.updateAppMessageShareData({
            title:nickname+"向您推荐"+tmp, 
            desc: custom_title, // 分享描述
            link: SERVER_PATH+'h5_gate.php?u='+shopinfo.u+'&s='+shopinfo.s+'&p='+path+'&a='+article_id+'&v='+course_id, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: pic,
            success: function () {
                // 用户点击了分享后执行的回调函数
                // Toast.info("分享成功",1);
            },
            fail: function (res) {
                // Toast.info(res.errMsg,1);

            }
        });
        window.wx.onMenuShareTimeline({
            title:nickname+"向您推荐 : "+custom_title+tmp, 
            link: SERVER_PATH+'h5_gate.php?u='+shopinfo.u+'&s='+shopinfo.s+'&p='+path+'&a='+article_id+'&v='+course_id, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: pic, // 分享图标
            success: function () {
                // 用户点击了分享后执行的回调函数
            },
        });
        window.wx.updateTimelineShareData({
            title:nickname+"向您推荐 : "+custom_title+tmp, 
            imgUrl: pic,
            link: SERVER_PATH+'h5_gate.php?u='+shopinfo.u+'&s='+shopinfo.s+'&p='+path+'&a='+article_id+'&v='+course_id, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致

            success: function () {
                // 用户点击了分享后执行的回调函数
                // Toast.info("分享成功",1);
            },
            fail: function (res) {
                // Toast.info(res.errMsg,1);

            }
        });

    }
}

// 分享文章
export function  share_content(nickname=null,shopinfo = null,path="/main",custom_title=null,article_id=null,course_id=null,tmp_pic=null){
    window.wx.showMenuItems({
        menuList: ["menuItem:share:appMessage", "menuItem:share:timeline"] // 要显示的菜单项，所有menu项见附录3
    });
    //console.log(shopinfo)
    if (shopinfo) {

        let pic=shopinfo.pic;
        let title=shopinfo.company;
        if(custom_title){
            title=custom_title;
        }
        if(tmp_pic){
            pic=tmp_pic;
        }
        let tmp=""
        if(shopinfo.share_benefit==1){
            tmp="(点此进入可享95折优惠)"
        }

  
        window.wx.updateAppMessageShareData({
            title:nickname+"向您推荐 : "+ title+tmp, // 分享标题
            desc: shopinfo.desc, // 分享描述
            link: SERVER_PATH+'h5_gate.php?u='+shopinfo.u+'&s='+shopinfo.s+'&p='+path+'&a='+article_id+'&v='+course_id, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: pic,
            success: function () {
                // 用户点击了分享后执行的回调函数
                // Toast.info("分享成功",1);
            },
            fail: function (res) {
                // Toast.info(res.errMsg,1);

            }
        });
        window.wx.onMenuShareTimeline({
            title:nickname+"向您推荐 : "+ title+tmp, // 分享标题
            link: SERVER_PATH+'h5_gate.php?u='+shopinfo.u+'&s='+shopinfo.s+'&p='+path+'&a='+article_id+'&v='+course_id, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: pic, // 分享图标
            success: function () {
                // 用户点击了分享后执行的回调函数
            },
        });
        window.wx.updateTimelineShareData({
            title:nickname+"向您推荐 : "+ title+tmp, // 分享标题
            imgUrl: pic,
            link: SERVER_PATH+'h5_gate.php?u='+shopinfo.u+'&s='+shopinfo.s+'&p='+path+'&a='+article_id+'&v='+course_id, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致

            success: function () {
                // 用户点击了分享后执行的回调函数
                // Toast.info("分享成功",1);
            },
            fail: function (res) {
                // Toast.info(res.errMsg,1);

            }
        });

    }
}


// 微信支付
export function wxpay(data) {
    return new Promise((resolve, reject) => {

        window.WeixinJSBridge.invoke(
            'getBrandWCPayRequest', data,
            function (res) {
                if (res.err_msg == "get_brand_wcpay_request:ok") {

                    resolve();
                    // 使用以上方式判断前端返回,微信团队郑重提示：
                    //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                } else if (res.err_msg == "get_brand_wcpay_request:fail") {
                    Toast.info("发起支付失败！", 1);

                }
            });
    });

}
// 校验手机号码
export function isPoneAvailable(value) {
    var myreg=/^[1][3,4,5,7,8][0-9]{9}$/;
    if (!myreg.test(value)) {
        return false;
    } else {
        return true;
    }
}
// 消息提示
export function toast(msg,time=1) {
    Toast.info(msg, time);
}

// 去除字符串所有空格
export function remove_blanket_str(str){
    return str.replace(/\s*/g,"");
}

// 两个整数相乘
export function save_multi(v1,v2){
    v1=parseInt(v1*100);
    v2=parseInt(v2*100);
    let total=v1*v2;
    // return parse_float( total*0.0001);
    return total/10000;
}
// 转化为浮点数
export  function parse_float(v){
    v=v*1000;
    v=parseInt(v);
    return v/1000;
}
// 获取当前时间
export function get_current_date(){
    let myDate=new Date();
    let y=myDate.getFullYear();
    let m=myDate.getMonth()+1;
    let d=myDate.getDate();
    let h=myDate.getHours();
    let i=myDate.getMinutes();
    let s=myDate.getSeconds();
    if(m<10){
        m='0'+m;
    }
    if(d<10){
        d='0'+d;
    }
    if(h<10){
        h='0'+h;
    }
    if(i<10){
        i='0'+i;
    }
    if(s<10){
        s='0'+s;
    }
    return  y+'-'+m+'-'+d+' '+h+':'+i+':'+s;
}



      
