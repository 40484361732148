import React from 'react'
import { connect } from "react-redux"
import { set_course_id, set_course_cate_id, set_course_search_title ,set_course_content_id} from '../../redux/action'
import Axios from '../../axios/index'
import { HashRouter, Route, Switch, Redirect, NavLink } from 'react-router-dom'
import { MAIN_SELECTED, VIDEO_SELECTED, STUDY_SELECTED, PERSON_SELECTED } from '../../config/footer'
import { SearchBar, Flex, WhiteSpace, WingBlank } from 'antd-mobile';
import './index.less'
import { hiden_wechat_menu } from "../../utils/index"
class Main extends React.Component {
        state = {
                course_search_title: '',
                goto_course: false,
                course_cate: [],
                new_course: [],
                free_course: []
        }
        // 跳转到全部专栏
        goto_course(course_cate_id) {
                const { dispatch } = this.props;
                dispatch(set_course_cate_id(course_cate_id));
        }
        // 跳转到专栏详情
        goto_course_detail(course_id) {
                const { dispatch } = this.props;
                dispatch(set_course_id(course_id));
                dispatch(set_course_content_id(""));

        }
        componentDidMount() {
                let that = this;
                Axios.ajax({
                        url: '/main.php',
                        data: {
                                token: this.props.token
                        }
                }).
                        then((res) => {
                                that.setState({
                                        new_course: res.data.new_video,
                                        free_course: res.data.free_course,
                                        course_cate:res.data.course_cate
                                })
                        })
                const { dispatch } = this.props;
                dispatch(set_course_search_title(""));
                dispatch(set_course_cate_id(""));
                dispatch(set_course_id(""));
                
        }
        change_search_key = (value) => {
                this.setState({
                        course_search_title: value
                })
        }
        // 搜索
        search() {
                const { dispatch } = this.props;
                dispatch(set_course_search_title(this.state.course_search_title));
                this.setState({
                        goto_course: true
                })
        }
        render() {

                if (this.props.shopinfo) { hiden_wechat_menu(this.props.shopinfo); }
                if (this.state.goto_course) {
                        return (
                                <Redirect to="/course" />
                        );
                }
                // 获取最新专栏
                let new_course_header = null;
                let shengque_view = null;
                if (this.state.new_course.length > 0) {
                        new_course_header = (
                                <div className="quyu-header-view">
                                        <text className="quyu-header-title">付费专栏</text>
                                </div>)
                } else {
                        shengque_view = (<div class="none-order">
                                <img src="/assets/quesheng_img.png" class="pic-zhangdan-style" />
                                <text class="none-order-font">暂无专栏</text>
                        </div>)
                }
                let new_course = this.state.new_course.map((item) => {
                 
                        return (
                                <NavLink className="course-list-view" key={item.id} to="/course_detail" onClick={this.goto_course_detail.bind(this, item.id)}>
                                        <div className="course-wrap-left">
                                                <img src={item.course_cover} className="course-cover-icon" />
                                                {/* {
                                                        item.count > 0 ? <div className="course-sale"> 已有 {item.count} 人学习</div> : ''
                                                } */}
                                        </div>
                                        <div className="course-wrap-right">
                                                <div className="course-title">{item.title}</div>
                                                {
                                                        item.tag != null ? <div className="course-description">{item.tag}</div> : ''
                                                }

                                                <div className="course-price">￥{item.price}</div>
                                        </div >
                                </NavLink >
                        )

                })
                // 获取免费专栏
                let free_course_header = null;
                let free_course = null;
                if (this.state.free_course.length > 0) {
                        free_course_header = (
                                <div className="quyu-header-view">
                                        <text className="quyu-header-title">免费专栏</text>
                                </div>)
                        free_course = this.state.free_course.map((item) => {
                                return (
                                        <NavLink className="course-list-view" key={item.id} to="/course_detail" onClick={this.goto_course_detail.bind(this, item.id)}>
                                                <div className="course-wrap-left">
                                                        <img src={item.course_cover} className="course-cover-icon" />
                                                        {
                                                                item.count > 0 ? <div className="course-sale"> 已学习 {item.count} 次</div> : ''
                                                        }
                                                </div>
                                                <div className="course-wrap-right">
                                                        <div className="course-title">{item.title}</div>
                                                        {
                                                                item.tag != null ? <div className="course-description">{item.tag}</div> : ''
                                                        }

                                                        <div className="course-price none-bold">免费</div>
                                                </div >
                                        </NavLink >
                                )

                        })

                }

                let defaultValue = this.state.course_search_title;
                // 获取专栏分类
                let course_cate_data=this.state.course_cate.map((item) => {
                        return (
                                <NavLink className="nav-module" to="/course" onClick={this.goto_course.bind(this, item.id)} >
                                                <img src={item.pic_url} className="nav-module-icon" />
                                                <text className="nav-module-span">{item.title}</text>
                               </NavLink>
                        )
                })
                let course_data_count=this.state.course_cate.length;
                return (
                        <div>
                                <SearchBar placeholder="请输入专栏名称" maxLength={20} value={defaultValue} onSubmit={this.search.bind(this)} onChange={this.change_search_key} />
                                {course_data_count>=3?<div className="nav-view" >
                                       {course_cate_data}
                                </div>:''}
                                
                                <div className="blanket-margin"></div>
                                {MAIN_SELECTED}
                                {new_course_header!=null?<div class="new-course-row">
                                        {new_course_header}
                                        {new_course}
                                </div>:''}

                                {free_course_header!=null?<div class="new-course">
                                        {free_course_header}
                                        {free_course}
                                </div>:''}
                                
                                {new_course_header==null&&free_course_header==null?shengque_view:''}
                                <div className="blanket-view"></div>
                        </div >
                );
        }
}
const mapStateToProps = state => {

        return {
                token: state.token,
                shopinfo: state.shopinfo
        }
};
export default connect(mapStateToProps)(Main)



