import React from 'react'
import { connect } from "react-redux"
import Axios from '../../axios/index'
import { Tabs, Modal, Toast, ActionSheet,SearchBar ,InputItem} from 'antd-mobile';
import './index.less'

import ReactPlayer from 'react-player'
import { hiden_wechat_menu, wxpay, share_article, toast, share_content } from "../../utils/index"
import { HashRouter, Route, Switch, Redirect, NavLink } from 'react-router-dom'
import * as fn from '../../utils/index'
import course from '../course';
import { set_course_id, set_course_cate_id, set_course_search_title ,set_course_content_id} from '../../redux/action'
import { message } from 'antd';
const prompt = Modal.prompt;
const operation = Modal.operation;
class Course_detail extends React.Component {
        state = {
                goto_bind_mobile: false,
                video_url: '',
                video_fee_gate: false,
                goto_pay_order: false,
                consult_modal_gate: false,
                course_info_tab: 0,
                course_info: {},
                goto_article_gate: false,
                current_video_index: -1,
                course_content_id: '',
                playing_gate: false,
                comment:"",
                comment_data:[],

                current_video_tag: -1,
                show_gate: false
        }
        consult = () => {
                let that = this;
                let server_phone = this.state.course_info.server_phone;
                if (!server_phone) {
                        that.show_consult_model();
                        return false;
                }
                ActionSheet.showActionSheetWithOptions({
                        options: [<span className="pay-font1">在线咨询客服</span>, <span className="pay-font1">拨打客服电话</span>],
                        maskClosable: true
                },
                        (buttonIndex) => {

                                if (buttonIndex == -1) return false;
                                else if (buttonIndex == 0) {
                                        that.show_consult_model();
                                }
                                else if (buttonIndex == 1) {
                                        window.location.href = "tel:" + server_phone;
                                }
                        });

        }
        componentDidMount() {
                if (this.props.shopinfo) { hiden_wechat_menu(this.props.shopinfo); }
                let that = this;

                this.setState({
                        course_content_id: that.props.course_content_id
                })
                this.initData();
        }
        initData(course_id=this.props.course_id) {
                if (!course_id) return false;
                let that = this;
                Axios.ajax({
                        url: '/course_detail.php',
                        data: {
                                token: this.props.token,
                                course_id: course_id
                        }
                }).
                        then((res) => {

                                that.setState({
                                        course_info: res.data
                                })

                                //已经购买，或者分享进来，定位到目录 
                                if (res.data.is_buy || that.props.course_content_id > 0) {
                                        that.setState({
                                                course_info_tab: 1
                                        })
                                }
                                // 通过推广选项卡进来
                                if (that.props.course_content_id == -1) {
                                        that.setState({
                                                course_info_tab: 0
                                        })
                                }

                                let current_video_index = 0;
                                let current_video_title = res.data.title;
                                let video_list = res.data.video_list;
                                let current_content_id = "";

                                // 初始化目录,定位到分享的视频
                                if (that.props.course_content_id) {
                                        if (video_list && video_list.length > 0) {

                                                for (let i = 0; i < video_list.length; i++) {
                                                        if (that.props.course_content_id == video_list[i].id) {
                                                                current_video_index = i;
                                                                current_video_title = current_video_title + " - " + video_list[i].title;
                                                        }
                                                }
                                        }
                                        current_content_id = that.props.course_content_id;
                                } else {
                                        // 不存在分享视频id,自动定位到第一个内容
                                        if (video_list && video_list.length > 0) {
                                                current_video_title = current_video_title + " - " + video_list[0].title;
                                                current_content_id = video_list[0].id
                                                that.setState({

                                                        current_content_id: current_content_id
                                                })

                                        }
                                }

                                // 用户已订阅过课程
                                if (res.data.is_pay) {
                                        // 开启分享
                                        share_article(res.data.nickname, that.props.shopinfo, '/course_detail', current_video_title, current_content_id, res.data.id, res.data.course_cover);
                                }
                                // console.log(current_video_index)
                                // 当有专栏时内容时，自动播放
                                if (res.data.video_list && res.data.video_list.length > 0 && res.data.video_list[current_video_index].type == 'VIDEO') {
                                        this.setState({
                                                current_video_index: current_video_index,
                                                current_content_id: current_content_id,
                                                playing_gate: false
                                        })
                                        setTimeout(function(){
                                                that.setState({
                                                        playing_gate:true
                                                })
                                                // fn.toast("执行计划");
                                        },100)
                                        this.initvideo(current_video_index);
                                }
                                that.init_commet_data();
                        })
        }

        // 初始化视频资源
        initvideo(index = 0) {
                let that = this;

                if (that.state.course_info.video_list && that.state.course_info.video_list.length > 0) {
                        // let video_fee_gate = false;
                        // if (that.state.course_info.video_list[index].is_free == 0) {
                        //         video_fee_gate = true;
                        // }
                        that.setState({
                                video_url: that.state.course_info.video_list[index].video_url
                                // video_fee_gate: video_fee_gate
                        })
                }
        }

        // 切换视频栏目
        change_video_index(index) {
                let that = this;
                let content_data = that.state.course_info.video_list[index];

                

                // 监控请求
                Axios.ajax({
                        url: '/content_check.php',
                        data: {
                                token: this.props.token,
                                content_id: content_data.id,
                                isShowLoading: true
                        }
                }).
                then((res) => {
                        console.log("监控请求成功!")

                        // 判断该课程是否试看
                        if (res.data.can_watch==false) {
                                that.setState({
                                        video_fee_gate: true
                                })
                      
                        }else{
                                that.setState({
                                        video_fee_gate: false
                                })  
                                // 文章直接调整
                                if (content_data.type == 'ARTICLE') {
                                        const { dispatch } = this.props;
                                        // console.log("哈哈"+content_data.id)
                                        dispatch(set_course_content_id(content_data.id));
                                 
                                        that.setState({
                                                goto_article_gate: true
                                        })  
                        
                                        
                                        
                                        return false;
                                }
                                // 更新分享链接
                                if (this.state.course_info.is_pay) {
                                        // 开启分享
                                        share_article(this.state.course_info.nickname, that.props.shopinfo, '/course_detail', that.state.course_info.title + ' - ' + content_data.title, content_data.id, this.state.course_info.id, this.state.course_info.course_cover);
                                }
                                this.setState({
                                        current_video_index: index,
                                        course_content_id: content_data.id,
                                        playing_gate: true
                                })
                        }
                        
                        this.initvideo(index)
                })

                

        }
        // 切换当前标签
        change_tag(index) {
                let that = this;
                this.setState({
                        current_video_tag: index
                })

        }
        // 购买专栏
        buy_course() {
                let that = this;
                let course_info = that.state.course_info;
                // 判断是否绑定手机号,未绑定则跳转到手机号绑定界面
                if (!that.state.course_info.is_bind_mobile) {
                        fn.toast("请先绑定手机号");
                        that.setState({
                                goto_bind_mobile: true
                        })
                        return false;
                }
                if (course_info.is_free == 1) {
                        // 免费专栏
                        that.use_wxpay();
                } else {
                        // 付费专栏，跳转到支付确认页
                        that.setState({
                                goto_pay_order: true
                        })
                        return false;
                       
                }
        }
        // 微信支付
        use_wxpay(type = 0) {
                let that = this;
                Axios.ajax({
                        url: '/h5_precreate.php',
                        data: {
                                type: type,
                                token: this.props.token,
                                course_id: this.props.course_id,
                                s: this.props.s,
                                isShowLoading: false
                        }
                }).
                        then((res) => {
                                if (res.hasOwnProperty('data')) {
                                        wxpay(res.data).then((res) => {
                                                that.initData();
                                        })
                                } else {
                                        fn.toast('订阅成功');
                                        that.initData();
                                }

                        })
        }
        change_tab(index) {
                let that = this;
                let current_content_id = that.state.current_content_id;


                that.setState({
                        course_info_tab: index
                })
                // 用户已订阅过课程
                if (this.state.course_info.is_pay) {
                        if (index == 2) {
                                // 切换到推广
                                share_content(this.state.course_info.nickname, that.props.shopinfo, '/course_detail', that.state.course_info.title, '-1', this.state.course_info.id, this.state.course_info.course_cover);
                        } else if (index == 1) {
                                // 计算当前的分享标题


                                let video_list = that.state.course_info.video_list;
                                let current_video_title = that.state.course_info.title;
                                if (video_list && video_list.length > 0) {

                                        for (let i = 0; i < video_list.length; i++) {
                                                if (current_content_id == video_list[i].id) {
                                                        current_video_title = current_video_title + " - " + video_list[i].title;
                                                }
                                        }
                                }
                                // 切换到目录
                                share_article(this.state.course_info.nickname, that.props.shopinfo, '/course_detail', current_video_title, current_content_id, this.state.course_info.id, this.state.course_info.course_cover);
                        }

                }

        }
        // 显示客服弹窗
        show_consult_model() {
                let that = this;
                that.setState({
                        consult_modal_gate: true
                })
        }
        // 隐藏客服弹窗
        hide_consult_model() {
                let that = this;
                that.setState({
                        consult_modal_gate: false
                })

        }
        // 激活码支付
        activate(activate_code) {
                if (activate_code == '') {
                        Toast.info("激活码不能为空!", 1);
                        return false;;
                }
                let that = this;
                Axios.ajax({
                        url: '/activate.php',
                        data: {
                                token: this.props.token,
                                course_id: this.props.course_id,
                                activate_code: activate_code,
                                isShowLoading: false
                        }
                }).
                        then((res) => {
                                Toast.info("激活成功!", 1);
                                that.initData();

                        })
        }
        // 显示分享引导
        show_share(current_video_index = this.state.current_video_index) {
                let that = this;

                if (!this.state.course_info.is_pay) {
                        // 未订阅专栏不可推广
                        toast('请先订阅任意付费专栏后，再进行操作！', 2);
                        return false;
                }

                let video_list = that.state.course_info.video_list;
                let title = that.state.course_info.title;
                let current_content_id = that.state.current_content_id;

                //  获取当前内容的id和title
                if (current_video_index > -1 && video_list && video_list.length > 0) {
                        title = title + " - " + video_list[current_video_index].title;
                        current_content_id = video_list[current_video_index].id;
                }

                // 用户已订阅过课程
                if (this.state.course_info.is_pay) {
                        // 推广选项卡样式
                        if (that.state.course_info_tab == 2) {
                                // 切换到推广
                                share_content(this.state.course_info.nickname, that.props.shopinfo, '/course_detail', that.state.course_info.title, '-1', this.state.course_info.id, this.state.course_info.course_cover);
                        } else {
                                // 开启分享
                                share_article(this.state.course_info.nickname, that.props.shopinfo, '/course_detail', title, current_content_id, this.state.course_info.id, this.state.course_info.course_cover);
                        }

                }
                this.setState({
                        show_gate: true
                })

        }
        // 隐藏分享引导
        hide_share() {
                this.setState({
                        show_gate: false
                })
        }
        // 视频暂停
        video_pause=()=> {
                // fn.toast("视频暂停");
         
                this.setState({
                        playing_gate:false
                })
        }

        // 视频暂停
        video_play=()=> {
                // fn.toast("视频播放");
                this.setState({
                        playing_gate:true
                })
        }

        video_error=()=>{
                // fn.toast("视频播放错误",2);
        }
        // 视频暂停
        video_start=()=> {
                // fn.toast("视频开始播放");
        }

        click_ad(course_id){
                let that=this;
                console.log(course_id)
                const { dispatch } = this.props;
                dispatch(set_course_id(course_id));
                that.initData(course_id)
        }
        change_comment=(e)=>{
                let that=this
                that.setState({
                        comment:e.target.value
                })

        }
        init_commet_data(){
                let that=this
                Axios.ajax({
                        url: '/comment_list.php',
                        data: {
                                token: this.props.token,
                                course_id: this.props.course_id,
                                isShowLoading: false
                        }
                }).
                        then((res) => {
             
                                that.setState({
                                        comment_data:res.data
                                })

                        })
        }
        submit_comment=()=>{
                let that=this
                let comment=that.state.comment;
                if(comment==""){
                        Toast.info("评论不能为空！", 1);
                        return false;
                }
                Axios.ajax({
                        url: '/comment_add.php',
                        data: {
                                token: this.props.token,
                                course_id: this.props.course_id,
                                comment:comment,
                                isShowLoading: false
                        }
                }).
                        then((res) => {
                                Toast.info("发表成功", 1);
                                that.setState({
                                        comment:''
                                })
                                that.init_commet_data();
                                that.change_tab(2)

                        })

        }
        render() {
                let that = this;
                let show_gate = that.state.show_gate;
                let vide_tag_view = null;
                if (!this.props.course_id) {
                        return (<Redirect to="/main" />)
                }
                if (this.state.goto_article_gate) {
                        return (<Redirect to="/article_detail" />)
                }
                if (this.state.goto_bind_mobile) {
                        return (<Redirect to='/bind_mobile' />)
                }
                if (that.state.goto_pay_order) {
                        return (<Redirect to='/pay_order' />)
                }


                let video_list = null
                let shengque_view = null
                if (this.state.course_info.video_list && this.state.course_info.video_list.length > 0) {
                        // 视频标签
                        let video_tag_list_view = this.state.course_info.video_tags.map((item, index) => {
                                return <div className={this.state.current_video_tag == index ? "tag-row-active" : "tag-row"} onClick={this.change_tag.bind(this, index)}>{item.name}</div>
                        })
                        vide_tag_view = (<div className="video-tag-view">
                                <div className={this.state.current_video_tag == -1 ? "tag-row-active" : "tag-row"} onClick={this.change_tag.bind(this, -1)}>全部</div>
                                {video_tag_list_view}
                        </div>)
                        video_list = this.state.course_info.video_list.map((item, index) => {
                                
                                if (that.state.current_video_tag != -1 ) {
                                        let tmp_gate=false;
                                        for(let i=0;i<item.tag_id.length;i++){
                                                if(item.tag_id[i]==that.state.course_info.video_tags[that.state.current_video_tag].id){
                                                        tmp_gate=true;
                                                        break;
                                                }
                                        }
                                        if ( !tmp_gate) {
                                                return null;
                                        }
                                }
                                

                                
                                if (item.type == 'VIDEO') {
                                        return (
                                                <div className="course-cate-list " id={{ index }} >
                                                        <div className="course-cate-left-wrap ">
                                                                {item.type == 'VIDEO' ? <img src="/assets/video_icon.png" className="course-detail-tag-icon" /> : <img src="/assets/article_icon.png" className="course-detail-tag-icon" />}

                                                        </div>
                                                        <div className={index == this.state.current_video_index ? 'course-cate-right-wrap video-list-selected' : 'course-cate-right-wrap'} onClick={this.change_video_index.bind(this, index)}>
                                                                {item.title}
                                                        </div>
                                                        
                                                        

                                                        {this.state.course_info.is_buy && item.is_free == 1 ? <div className="content-share-div"><img src="/assets/share_icon.png" className="share-content-icon" onClick={this.show_share.bind(this, index)} /><text className="share-label-style">分享有奖</text></div> : ''}
                                                        {this.state.course_info.is_buy ? '' : <text className="free-font">{item.is_free == 1 ? '试看' : '需订阅'} </text>
                                                        }
                                                        {item.is_new==1?<img src="/assets/content_news3.png" className="content-news-style"/>:""}
                                                        
                                                </div>
                                        )
                                } else if (item.type == 'ARTICLE') {
                                        // 跳转到图文界面
                                        return (
                                                <div className="course-cate-list " id={{ index }} onClick={this.change_video_index.bind(this, index)}>
                                                        <div className="course-cate-left-wrap ">
                                                                {item.type == 'VIDEO' ? <img src="/assets/video_icon.png" className="course-detail-tag-icon" /> : <img src="/assets/article_icon.png" className="course-detail-tag-icon" />}

                                                        </div>
                                                        <div className={index == this.state.current_video_index ? 'course-cate-right-wrap video-list-selected' : 'course-cate-right-wrap'} >
                                                                {item.title}
                                                        </div>
                                                        

                                                        {this.state.course_info.is_buy ? '' : <text className="free-font">{item.is_free == 1 ? '试看' : '需订阅'} </text>
                                                        }
                                                        {item.is_new==1?<img src="/assets/content_news3.png" className="content-news-style"/>:""}
                                                </div>
                                        )
                                }

                        })
                } else {
                        shengque_view = (<div className="none-order" style={{ background: "none" }}>
                                <img src="/assets/quesheng_img.png" className="pic-zhangdan-style" />
                                <text className="none-order-font">正在准备内容</text>
                        </div>)

                }
                // 视频音频播放地址
                let video_url = this.state.video_url;
                let video_content = null;
                if (video_url == '') {
                        video_content = <img src={this.state.course_info.course_cover} className="course-cover-pic" />
                } else {
                        if (that.state.show_gate) {
                                // 开启分享时，隐藏播放
                                video_content = <img src={this.state.course_info.course_cover} className="course-cover-pic" />
                        } else {
                                // 关闭分享时，开启播放
                                video_content = <ReactPlayer className="video-course-playview" onError={this.video_error} onStart={this.video_start} onPlay={this.video_play} onPause={this.video_pause} url={video_url} controls width="100%" height="5.2rem" playing={that.state.playing_gate} />

                        }

                }
                // 免费时的显示按钮
                let buy_button_view = null;
                if (!this.state.course_info.is_buy) {
                        buy_button_view = <div className="course-footer-view"> <div className="course-footer-right-view" onClick={this.buy_course.bind(this)} >
                                <div className="course-footer-right-wrap">
                                        <img src="/assets/course_buy.png" className='consult-icon2' />
                                        <text className='course-footer-font2'> 立即订阅</text>
                                </div>
                        </div></div>
                }
                // 获取价格
                let price = null;
                if (this.state.course_info.price_rule) {
                        price=999999
                        if(this.state.course_info.price_rule.length==1){
                                price = this.state.course_info.price_rule[0].price;
                        }else{
                                for(let i=0;i<this.state.course_info.price_rule.length;i++){
                                        let tmp=parseInt(this.state.course_info.price_rule[i].price)
                                        if(tmp<price){
                                                price=tmp;
                                        }
                                }
                                price=""+price+" 起";
                        }
                }
                // 显示价格view
                let price_view = null
                if (!this.state.course_info.is_buy) {
                        if (this.state.course_info.is_free) {
                                price_view = <text className="video-price  none-bold">免费</text>
                        } else {
                                price_view = <text className="video-price">{'￥' + price}</text>
                        }
                }
                // 收费专栏显示推广模块，免费专栏不显示
                let tabs = null;
                let price_rule_view = null;
                let pinlun_str="评论"
                if(that.state.comment_data&&that.state.comment_data.length>0){
                        pinlun_str=pinlun_str+"("+that.state.comment_data.length+")"
                }
                if (that.state.course_info.is_free == 0) {
                        tabs = [
                                { title: '详情' },
                                { title: '目录' },
                                { title: pinlun_str },
                                { title: '推广' }
                        ];
                        let price_rule = that.state.course_info.price_rule;
                        let share_one_rate = that.state.course_info.share_one_rate * 0.01;
                        let share_two_rate = that.state.course_info.share_two_rate * 0.01;
                        // 高级推广比例
                        let super_share_one_rate = that.state.course_info.super_share_one_rate * 0.01;
                        let super_share_two_rate = that.state.course_info.super_share_two_rate * 0.01;
                        // 当支付规则存在时
                        if (price_rule) {
                                price_rule_view = price_rule.map((item) => {
                                        let one_rate = fn.save_multi(item.price, share_one_rate)
                                        let two_rate = fn.save_multi(item.price, share_two_rate)

                                        let super_one_rate = fn.save_multi(item.price, super_share_one_rate)
                                        let super_two_rate = fn.save_multi(item.price, super_share_two_rate)

                                        return <div className="promot-row-view">
                                                <div className="promot-row-wrap-view">
                                                        <text className="promot-label">订阅{item.time}个月 ：</text>
                                                        <text className="promot-value">￥{item.price}</text>
                                                </div>
                                                <div className="promot-row-wrap-view">
                                                        <text className="promot-label">普通直接推广佣金({item.time}个月) ：</text>
                                                        <text className="promot-value">￥{one_rate}</text>
                                                </div>

                                                <div className="promot-row-wrap-view">
                                                        <text className="promot-label">普通间接推广佣金({item.time}个月) ：</text>
                                                        <text className="promot-value">￥{two_rate}</text>
                                                </div>
                                                <div className="promot-row-wrap-view">
                                                        <text className="promot-label">高级直接推广佣金({item.time}个月) ：</text>
                                                        <text className="promot-value">￥{super_one_rate}</text>
                                                </div>
                                                <div className="promot-row-wrap-view">
                                                        <text className="promot-label">高级间接推广佣金({item.time}个月) ：</text>
                                                        <text className="promot-value">￥{super_two_rate}</text>
                                                </div>
                                        </div>
                                })
                        }
                } else {
                        tabs = [
                                { title: '详情' },
                                { title: '目录' },
                                { title: pinlun_str }
                        ];
                }
                let share_view = (<div className={show_gate ? "share-bg-window display-flex" : "share-bg-window display-none"} onClick={this.hide_share.bind(this)}>
                        <div className="share-bg-level-one"></div>
                        <img src="/assets/share_icon_bg.png" className="share-icon-style" />
                </div>);

                let promot_button_label = "点击推广";
                if (this.state.course_info.is_pay) {
                        promot_button_label = "普通推广";
                        if (this.state.course_info.is_buy) {
                                promot_button_label = "高级推广";
                        }
                }
                let ads=null

                if(that.state.course_info.ads!=""&&that.state.course_info.ads!=undefined){
                        ads=that.state.course_info.ads.map((item)=>{
                                return   <div className="tag-row-active" onClick={that.click_ad.bind(this,item.id)}>{item.title}</div>                      
                        })
                }
                let comment_view=null

                if(that.state.comment_data&&that.state.comment_data.length>0){
                        comment_view=that.state.comment_data.map((item)=>{
                                return (<div className="user-comment-row-view">
                                <div className="user-comment-row-left-view">
                                       <img src={item.headimage} className="image-style"/>
                                </div>
                               
                               <div className="user-comment-row-right-view">
                                       <text className="user-comment-title">{item.nickname}</text>
                                       <text className="user-comment-content">{item.content}</text>
                                       <text className="user-comment-time">{item.create_time}</text>
                               </div>
                               
                          </div>)
                        })
                }
                
                return (
                        
                        <div>  
                                  {that.props.course_content_id ? <NavLink to="/main"><div className="header-view">
                                <img src="/assets/arrow_left.png" className="arrow-left-style" />
                                <text className="header-font1">专栏详情</text>
                        </div></NavLink> : ''}

                                {
                                        this.state.course_info.is_buy == false && this.state.video_fee_gate == true ? <div className="course-cover-view" >
                                                <text className="course-cover-label"> 本专栏需订阅后观看</text>
                                                <div className="course-cover-buy" onClick={this.buy_course.bind(this)}>
                                                        <text  >立即订阅</text>
                                                </div>
                                        </div> : video_content
                                }
                                 
                                <div className="video-info">
                                        <div className="video-title">{this.state.course_info.title}</div>
                                        <div className="video-param-view">
                                                {/* <text className="video-shop-num">已学习人数 {this.state.course_info.count} 人</text> */}
                                                <text className="video-shop-num">已更新 {this.state.course_info.video_count} 期</text>
                                        </div>
                                
                                        <div className="consult-view" onClick={this.consult.bind(this)}>
                                                <img src="/assets/consult_icon.png" className="consult-style" />
                                        </div>
                                        {price_view}
                                </div>
                                {ads!=null?<div className="ad-info-view">
                                        <text className="normal-font">其它专栏：</text>
                                        <div className="ad-content-view">
                                        {ads}
                                        </div>
                                        
                                        <NavLink className="normal-font" to="/main" >全部</NavLink>
                
                                </div>:''}
                                
                                {this.state.course_info.is_buy && this.state.course_info.is_free == 0 ? <div className="subscript-hint-view" >
                                        <div className="subscript-hint-left">
                                                <text className="font1-a4">{this.state.course_info.use_learn==1?"体验期到：":"有效期到："}</text>
                                                <text className={this.state.course_info.expire_is_hint ? 'font3-red' : 'font1-a4'}>{this.state.course_info.expire_time}</text>
                                        </div>
                                        <div className="subscript-hint-right" onClick={this.buy_course.bind(this)}>立即续费</div>
                                </div> : ''}
                                <Tabs tabs={tabs} onChange={(tab, index) => { that.change_tab(index); }} swipeable={false} page={that.state.course_info_tab} animated={false} useOnPan={false} tabBarUnderlineStyle={{ backgroundColor: '#108ee9' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: 'auto', backgroundColor: '#fff' }}>
                                                <div className="course-detail" dangerouslySetInnerHTML={{ __html: this.state.course_info.detail }} />
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', height: 'auto' }}>
                                                {shengque_view}
                                                {vide_tag_view}
                                                <div className="course-cate-view">
                                                        {video_list}
                                                </div>
                                        </div>
                                        <div className="user-comment-view">
                                                {comment_view==null?<div className="shengqueview">
                                                        <text className="normal-font">暂无评论</text>  
                                                </div>:comment_view}
                                                
 
                                      
                                        </div>
                                        <div className="promote-info-view">

                                                <div className="promote-info-left-view">{price_rule_view}</div>
                                                <div className="promote-button-now" onClick={this.show_share.bind(this)}>{promot_button_label}</div>
                                        </div>
                                </Tabs>
                                <div className="blanket-view"></div>
                                {buy_button_view}
                                <Modal
                                        visible={that.state.consult_modal_gate}
                                        transparent
                                        onClose={that.hide_consult_model.bind(this)}
                                        maskClosable={true}
                                        afterClose={() => { that.hide_consult_model(); }}
                                >
                                        <div className="consult-modal-wrap">
                                                <div className="consult-modal-wrap-title"> 长按下方二维码添加客服微信</div>
                                                {that.state.course_info.server_qr_code ? <img src={that.state.course_info.server_qr_code} className="consult-qrcode-icon" /> : <div className="consult-qrcode-none">暂无客服微信</div>}
                                        </div>
                                </Modal>
                                {share_view}
                                {this.state.course_info.is_buy?<div className="comment-view">
                                        <textarea className="comment-style" placeholder="客观评论，理性发言，欢迎分享使用心得" maxLength={100} value={that.state.comment}  onChange={this.change_comment} />
                                        <div className="comment-submit" onClick={that.submit_comment}>发表</div>
                                </div>:''}
                                
                        </div>

                );
        }
}
const mapStateToProps = state => {

        return {
                token: state.token,
                shopinfo: state.shopinfo,
                s: state.s,
                course_id: state.course_id,
                course_content_id: state.course_content_id
        }
};
export default connect(mapStateToProps)(Course_detail)