import React from 'react'
import { NavLink } from 'react-router-dom'
// 首页
const MAIN_SELECTED=<div className="app-footer-view">
<div className="app-footer-module-view" >
    <img src="/assets/main_selected_big.png" className="app-footer-module-icon"/>
    <text className="app-footer-module-label-selected">首页</text>
</div>
<NavLink className="app-footer-module-view"   to="/course"   >
    <img src="/assets/video_big.png" className="app-footer-module-icon"/>
    <text className="app-footer-module-label">专栏中心</text>
</NavLink>
<NavLink className="app-footer-module-view"   to="/study"  >
    <img src="/assets/study_big.png" className="app-footer-module-icon"/>
    <text className="app-footer-module-label">我的订阅</text>
</NavLink>
<NavLink className="app-footer-module-view"   to="/person"  >
    <img src="/assets/person_big.png" className="app-footer-module-icon"/>
    <text className="app-footer-module-label">个人中心</text>
</NavLink>
</div>
// 专栏中心
const VIDEO_SELECTED=<div className="app-footer-view">
<NavLink className="app-footer-module-view"   to="/main"  >
    <img src="/assets/main_big.png" className="app-footer-module-icon"/>
    <text className="app-footer-module-label">首页</text>
</NavLink>
<div className="app-footer-module-view"  >
    <img src="/assets/video_selected_big.png"  className="app-footer-module-icon"/>
    <text className="app-footer-module-label-selected">专栏中心</text>
</div>
<NavLink className="app-footer-module-view"   to="/study"  >
    <img src="/assets/study_big.png" className="app-footer-module-icon"/>
    <text className="app-footer-module-label">我的订阅</text>
</NavLink>
<NavLink className="app-footer-module-view"   to="/person"  >
    <img src="/assets/person_big.png" className="app-footer-module-icon"/>
    <text className="app-footer-module-label">个人中心</text>
</NavLink>
</div>
// 我的订阅
const STUDY_SELECTED=<div className="app-footer-view">
<NavLink className="app-footer-module-view"   to="/main"  >
    <img src="/assets/main_big.png" className="app-footer-module-icon"/>
    <text className="app-footer-module-label">首页</text>
</NavLink>
<NavLink className="app-footer-module-view"   to="/course"  >
    <img src="/assets/video_big.png" className="app-footer-module-icon"/>
    <text className="app-footer-module-label">专栏中心</text>
</NavLink>
<div className="app-footer-module-view" >
    <img src="/assets/study_selected_big.png" className="app-footer-module-icon"/>
    <text className="app-footer-module-label-selected">我的订阅</text>
</div>
<NavLink className="app-footer-module-view"   to="/person"  >
    <img src="/assets/person_big.png" className="app-footer-module-icon"/>
    <text className="app-footer-module-label">个人中心</text>
</NavLink>
</div>
// 个人中心
const PERSON_SELECTED=<div className="app-footer-view">
<NavLink className="app-footer-module-view"   to="/main"  >
    <img src="/assets/main_big.png" className="app-footer-module-icon"/>
    <text className="app-footer-module-label">首页</text>
</NavLink>
<NavLink className="app-footer-module-view"   to="/course"  >
    <img src="/assets/video_big.png" className="app-footer-module-icon"/>
    <text className="app-footer-module-label">专栏中心</text>
</NavLink>
<NavLink className="app-footer-module-view"   to="/study"  >
    <img src="/assets/study_big.png" className="app-footer-module-icon"/>
    <text className="app-footer-module-label">我的订阅</text>
</NavLink>
<div className="app-footer-module-view"  >
    <img src="/assets/person_selected_big.png" className="app-footer-module-icon"/>
    <text className="app-footer-module-label-selected">个人中心</text>
</div>
</div>

export {MAIN_SELECTED,VIDEO_SELECTED,STUDY_SELECTED,PERSON_SELECTED};