import React from 'react'
import { connect } from "react-redux"
import Axios from '../../axios/index'
import './index.less'
import { set_course_id ,set_learn_coupon_id} from '../../redux/action'
import { List, InputItem } from 'antd-mobile';
import { Button } from 'antd-mobile';
import * as fn from '../../utils/index'
import { HashRouter, Route, Switch, Redirect, NavLink } from 'react-router-dom'
import { hiden_wechat_menu, getUrlParam } from "../../utils/index"
class Get_coupon extends React.Component {
        state = {
                coupon_id: '',
                coupon_info: {}
        }

        componentDidMount() {
                this.init_data();
        }
        init_data() {
                let that = this;
                let coupon_id = getUrlParam("c");
                if (coupon_id) {
                        that.setState({
                                coupon_id: coupon_id
                        })
                }
                let data = {};
                data.token = that.props.token;
                data.coupon_id = coupon_id;
                Axios.ajax({
                        url: '/coupon_info.php',
                        data: data
                }).then((res) => {
                        that.setState({
                                coupon_info: res.data
                        })
                })
        }
        // 跳转到全部课程
        goto_course_detail(course_id) {
                const { dispatch } = this.props;
                dispatch(set_course_id(course_id));

        }
        goto_pay_order(course_id){
                const { dispatch } = this.props;
                dispatch(set_course_id(course_id));
                dispatch(set_learn_coupon_id(this.state.coupon_info.id))

        }
        // 领取优惠券
        get_coupon() {
                let that = this;
                let data = {};
                data.token = this.props.token;
                data.coupon_id = this.state.coupon_id;
                data.isShowLoading = false;
                Axios.ajax({
                        url: '/gain_coupon.php',
                        data: data
                }).then((res) => {
                        fn.toast("领取成功");
                        that.init_data();
                })
        }
        render() {
                let coupon_info = this.state.coupon_info;
                if (this.props.shopinfo) { hiden_wechat_menu(this.props.shopinfo); }
                let is_gain = coupon_info.is_gain;
                let is_end = coupon_info.is_end;
                let header_view = null;
                let button_view = null;
                if (is_gain) {
                        header_view = <div className="coupon-header-view">
                                <text className="coupon-info-font4">您有一张{coupon_info.title}待使用!</text>
                                <text className="coupon-info-font4">请尽快使用，以防失效!</text>
                        </div>
                        if(coupon_info.coupon_type=="LEARN"){
                                //跳转到支付页面
                                button_view = <NavLink  to="/pay_order"onClick={this.goto_pay_order.bind(this, coupon_info.course_id)}><div className="coupon-button" >
                                已领券成功，去使用
                                </div></NavLink>
                        }else{
                                if(coupon_info.course_id==-1){
                                        button_view = <NavLink to='/main'><div className="coupon-button" >
                                        已领券成功，去使用
                                        </div></NavLink>
                                }else{
                                        // 跳转到对应专栏
                                        button_view = <NavLink  to="/course_detail" onClick={this.goto_course_detail.bind(this, coupon_info.course_id)}><div className="coupon-button" >
                                        已领券成功，去使用
                                        </div></NavLink>
                                }

                        }
                        
                        
                } else {

                        if (is_end) {
                                button_view = <div className="coupon-button touming" >
                                        暂无库存
                                </div>
                                header_view = <div className="coupon-header-view">
                                        <text className="coupon-info-font4">亲，您来晚了！</text>
                                        <text className="coupon-info-font4">优惠券已发放完毕！</text>
                                </div>
                        } else {
                                header_view = <div className="coupon-header-view">
                                        <text className="coupon-info-font4">恭喜您获得一张{coupon_info.title}!</text>
                                        <text className="coupon-info-font4">请及时领取!</text>
                                </div>
                                button_view = <div className="coupon-button" onClick={this.get_coupon.bind(this)}>
                                        立即领取
                                        </div>
                        }
                }



                return (
                        <div className="get_coupon_contain_view" >
                                {header_view}
                                <div className="coupon-info-view">
                                        <div className="coupon-info-left-view">
                                                {coupon_info.coupon_type=="LEARN"?<text>体验券</text>:<text>￥{coupon_info.amount}</text>}
                                        </div>
                                        <div className="coupon-info-right-view">
                                                <text className="coupon-info-font1">{coupon_info.title}</text>
                                                <text className="coupon-info-font3">有效期：{coupon_info.expire}天</text>
                                                {coupon_info.coupon_type=="LEARN"?<text className="coupon-info-font3">本券可试用 {coupon_info.learn_time} 天</text>:<text className="coupon-info-font3">支付时自动抵扣{coupon_info.limit_price>0?"（满"+coupon_info.limit_price+"可用）":''}</text>}
                                                
                                                <text className="coupon-info-font3">{coupon_info.coupon_type_hint}</text>
                                        </div>
                                </div>

                                {button_view}
                        </div >
                );
        }
}
const mapStateToProps = state => {

        return {
                token: state.token,
                shopinfo: state.shopinfo
        }
};
export default connect(mapStateToProps)(Get_coupon)