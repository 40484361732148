import React from 'react'
import { connect } from "react-redux"
import Axios from '../../axios/index'
import { MAIN_SELECTED, VIDEO_SELECTED, STUDY_SELECTED, PERSON_SELECTED } from '../../config/footer'
import { SearchBar, Flex, WhiteSpace, WingBlank } from 'antd-mobile';
import './index.less'
import { NavLink } from 'react-router-dom'
import { hiden_wechat_menu } from "../../utils/index"
class Coupon extends React.Component {
        state = {
                active_tab: 0,
                expire: [],
                destrory: [],
                none_use: []
        }
        change_tab(v) {
                this.setState({
                        active_tab: v
                })
        }
        componentDidMount() {
                this.init_data();

        }
        // 初始化数据
        init_data() {
                let that = this;
                Axios.ajax({
                        url: '/user_coupon.php',
                        data: {
                                token: this.props.token
                        }
                }).
                        then((res) => {

                                that.setState({
                                        expire: res.data.expire,
                                        destrory: res.data.destrory,
                                        none_use: res.data.none_use
                                })

                        })
        }
        render() {
                let expire = this.state.expire;
                let destrory = this.state.destrory;
                let none_use = this.state.none_use;

                let expire_view = null;
                let destrory_view = null;
                let none_use_view = null;
                let content_view = null;
                if (this.props.shopinfo) { hiden_wechat_menu(this.props.shopinfo); }
                // 渲染数据
                none_use_view = none_use.map((item) => {
                        item.amount=parseInt(item.amount)
                        return (
                                <div className="coupon-info-list-view">
                                        {item.coupon_type=="LEARN"?<div className="coupon-info-left-view">
                                                <text>体验券</text>
                                        </div>:<div className="coupon-info-left-view">
                                                <text>￥{item.amount}</text>
                                                <text className="coupon-info-font4">{item.limit_price>0?"满 "+item.limit_price+" 可用":'支付时自动抵扣'}</text>
                                        </div>}
                                        <div className="coupon-info-right-view">
                                                <text className="coupon-info-font1">{item.title}</text>
                                                <text className="coupon-info-font3">有效期：{item.expire_time}</text>
                                                <text className="coupon-info-font3">{item.course_id==-1?"全场通用":"限《"+item.course_title+"》使用"}</text>
                                        </div>
                                        <div className="coupon-info-arrow">
                                                待使用
                                        </div>
                                </div>
                        )
                })
                destrory_view = destrory.map((item) => {
                        item.amount=parseInt(item.amount)
                        return (
                                <div className="coupon-info-list-view">
                                        {item.coupon_type=="LEARN"?<div className="coupon-info-left-view">
                                                <text>体验券</text>
                                        </div>:<div className="coupon-info-left-view">
                                                <text>￥{item.amount}</text>
                                                <text className="coupon-info-font4">{item.limit_price>0?"满 "+item.limit_price+" 可用":'支付时自动抵扣'}</text>
                                        </div>}
                                        <div className="coupon-info-right-view">
                                                <text className="coupon-info-font1">{item.title}</text>
                                                <text className="coupon-info-font3">有效期：{item.expire_time}</text>
                                                <text className="coupon-info-font3">{item.course_id==-1?"全场通用":"限《"+item.course_title+"》使用"}</text>
                                        </div>
                                        <div className="coupon-info-arrow-gray">
                                                已使用
                                        </div>
                                </div>
                        )
                })
                expire_view = expire.map((item) => {
                        item.amount=parseInt(item.amount)
                        return (
                                <div className="coupon-info-list-view">
                                        {item.coupon_type=="LEARN"?<div className="coupon-info-left-view">
                                                <text>体验券</text>
                                        </div>:<div className="coupon-info-left-view">
                                                <text>￥{item.amount}</text>
                                                <text className="coupon-info-font4">{item.limit_price>0?"满 "+item.limit_price+" 可用":'支付时自动抵扣'}</text>
                                        </div>}
                                        
                                        <div className="coupon-info-right-view">
                                                <text className="coupon-info-font1">{item.title}</text>
                                                <text className="coupon-info-font3">有效期：{item.expire_time}</text>
                                                <text className="coupon-info-font3">{item.course_id==-1?"全场通用":"限《"+item.course_title+"》使用"}</text>
                                        </div>
                                        <div className="coupon-info-arrow-gray">
                                                已过期
                                        </div>
                                </div>
                        )
                })

                // 切换tab
                if (this.state.active_tab == 0) {
                        content_view = none_use_view;
                } else if (this.state.active_tab == 1) {
                        content_view = destrory_view;
                } else if (this.state.active_tab == 2) {
                        content_view = expire_view;
                }

                return (
                        <div>
                                <div className="distribute-nav-view">
                                        <div className={this.state.active_tab == 0 ? 'nav-row-view nav-active' : 'nav-row-view'} onClick={this.change_tab.bind(this, 0)}>未使用</div>
                                        <div className={this.state.active_tab == 1 ? 'nav-row-view nav-active' : 'nav-row-view'} onClick={this.change_tab.bind(this, 1)}>已使用</div>
                                        <div className={this.state.active_tab == 2 ? 'nav-row-view nav-active' : 'nav-row-view'} onClick={this.change_tab.bind(this, 2)}>已过期</div>
                                </div>

                                {content_view}

                        </div >

                );
        }
}
const mapStateToProps = state => {

        return {
                token: state.token, shopinfo: state.shopinfo

        }
};
export default connect(mapStateToProps)(Coupon)