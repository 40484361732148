import React from 'react'
import { connect } from "react-redux"
import { set_course_id } from '../../redux/action'
import { NavLink } from 'react-router-dom'
import { MAIN_SELECTED, VIDEO_SELECTED, STUDY_SELECTED, PERSON_SELECTED } from '../../config/footer'
import { SearchBar, Flex, WhiteSpace, WingBlank } from 'antd-mobile';
import './index.less'
import { hiden_wechat_menu } from "../../utils/index"
import * as fn from '../../utils/index'
import Axios from '../../axios/index'

class Study extends React.Component {
        state = {
                new_course: [],
                now_date: ''
        }
        goto_course(index) {
                console.log(index);
        }
        // 跳转到全部课程
        goto_course_detail(course_id) {
                const { dispatch } = this.props;
                dispatch(set_course_id(course_id));

        }
        componentDidMount() {
                let that=this;
                that.initData();
        }
        initData(){
                let that = this;
                Axios.ajax({
                        url: '/course_order.php',
                        data: {
                                token: this.props.token
                        }
                }).
                        then((res) => {
                                that.setState({
                                        new_course: res.data
                                })
                        })
                let now_date = fn.get_current_date();
                that.setState({
                        now_date: now_date
                })
        }
        // 微信支付订阅续费
        renew_wxpay(course_id) {
                let that = this;
  
                Axios.ajax({
                        url: '/h5_precreate.php',
                        data: {
                                token: this.props.token,
                                course_id: course_id,
                                s: this.props.s,
                                isShowLoading: false
                        }
                }).
                        then((res) => {
                                if (res.hasOwnProperty('data')) {
                                        fn.wxpay(res.data).then((res) => {
                                                that.initData();
                                        })
                                } else {
                                        fn.toast('续费成功');
                                        that.initData();
                                }

                        })
        }
        render() {
                let that = this;
                if (this.props.shopinfo) { hiden_wechat_menu(this.props.shopinfo); }
                let shengque_view = null;
                if (this.state.new_course == undefined || this.state.new_course.length == 0) {
                        shengque_view = (<div className="none-order" style={{ marginTop: "100px" }}>
                                <img src="/assets/quesheng_img.png" className="pic-zhangdan-style" />
                                <text className="none-order-font">暂无课程</text>
                        </div>)
                }

                let new_course = null;

                if (this.state.new_course) {
                        new_course = this.state.new_course.map((item) => {
                                return (
                                        <NavLink key={item.id} to="/course_detail" onClick={this.goto_course_detail.bind(this, item.id)}>
                                        <div className="margin-bottom30"  >
                                                <div className="course-list-header-view">
                                                        <div className="course-order-view">
                                                                订单编号 :{item.out_trade_no}
                                                        </div>
                                                        {
                                                                item.expire_time <= that.state.now_date ? <div className="expire-buy">已到期</div> : <div className="finish-buy">已订阅</div>
                                                        }

                                                </div>
                                                <div className="course-list-view" >
                                                        <div className="course-wrap-left">
                                                                <img src={item.course_cover} className="course-cover-icon" />
                                                        </div>
                                                        <div className="course-wrap-right">
                                                                <div className="course-title">{item.title}</div>
                                                                {
                                                                        item.tag != null ? <div className="course-description">{item.tag}</div> : ''
                                                                }
                                                                {/* <div class="course-study">
                                                                        进入学习
                                                                </div> */}
                                                        </div >
                                                </div>
                                                <div className="course-list-footer-view">
                                                        <div className="course-list-footer-wrap-left">
                                                                <div className="course-list-footer-row2">
                                                                        订阅时间 : {item.create_time}
                                                                </div>
                                                                <div className={item.is_hint?'course-list-footer-row2 redcolor':'course-list-footer-row2'}>
                                                                        有效日期 : {item.expire_time}
                                                                </div>
                                                        </div>
                                                        {/* <div className="course-list-footer-wrap-right">
                                                                <div class="course-study" onClick={this.renew_wxpay.bind(this, item.id)} >立即续费</div>
                                                        </div> */}
                                                </div>
                                        </div>
                                        </NavLink>
                                )

                        })
                }
                return (
                        <div >

                                {new_course}
                                {shengque_view}
                                <div className="blanket-view"></div>
                                {STUDY_SELECTED}
                        </div >


                );
        }
}
const mapStateToProps = state => {

        return {
                token: state.token, shopinfo: state.shopinfo,
                s:state.s
        }
};
export default connect(mapStateToProps)(Study)